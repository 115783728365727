import { useMemo } from 'react';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { isTxCustomFieldVisible } from 'domains/transaction/utils';
import { Box, Chip, Paper, Typography } from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import { Card, CardCustomFieldItem, CustomField } from 'services/constants';
import { useCanUser } from 'services/rbac';

// filter custom fields based on app restrictions and default value
const getFilteredFields = (
  transactionCustomFields: CustomField[],
  cardTxnCustomFields: CardCustomFieldItem[] | undefined,
  isAdminApp: boolean
) => {
  if (!cardTxnCustomFields?.length) return [];

  // hash map for easy access to custom fields
  const hashMappedTxCustomFields: {
    [id: string]: CustomField;
  } = transactionCustomFields.reduce(
    (result, item) => ({ ...result, [item.id]: item }),
    {}
  );

  const filteredCustomFields = cardTxnCustomFields.filter((item) => {
    if (!hashMappedTxCustomFields[item.transactionCustomFieldId]) return false;
    return (
      item.defaultValueForCard &&
      isTxCustomFieldVisible(
        hashMappedTxCustomFields[item.transactionCustomFieldId],
        isAdminApp
      )
    );
  });

  return sortBy(filteredCustomFields, (cf) => cf.label.toLowerCase());
};

const CustomFieldsChips = ({
  customFields,
}: {
  customFields: Card['cardTxnCustomFields'];
}) => (
  <Box mt={1}>
    {customFields.map((customField) => (
      <Chip
        sx={{ mr: 1, mb: 1 }}
        key={customField.transactionCustomFieldId}
        label={`${customField.label}: ${
          customField.defaultValueForCard || '-'
        }`}
      />
    ))}
  </Box>
);

interface Props {
  card: Card | null | undefined;
}

const CardCustomFields = ({ card }: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const { isCardholderApp, isAdminApp } = useCurrentApp();
  const {
    state: { featureModules, transactionCustomFields },
  } = useGlobalState();
  const shownCardTransactionCustomFields = useMemo(() => {
    if (
      !featureModules.TRANSACTION_CUSTOM_FIELDS ||
      !transactionCustomFields.length ||
      !canUser('custom-fields:view')
    )
      return [];
    return getFilteredFields(
      transactionCustomFields,
      card?.cardTxnCustomFields,
      isAdminApp
    );
  }, [card]);

  if (!shownCardTransactionCustomFields.length) return null;

  return isCardholderApp ? (
    <Box mt={2}>
      <Paper
        variant="outlined"
        sx={(theme) => ({
          padding: theme.spacing(2, 1.5, 2, 2),
          mt: 2,
        })}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          mt={-0.5}
        >
          {t('cardDetails.customFields')}
        </Typography>
        <CustomFieldsChips customFields={shownCardTransactionCustomFields} />
      </Paper>
    </Box>
  ) : (
    <Box
      p={3}
      sx={(theme) => ({
        borderTop: `1px solid ${theme.palette.divider}`,
      })}
    >
      <Typography variant="overline" component="div" py={0.5}>
        {t('cardDetails.customFields')}
      </Typography>
      <CustomFieldsChips customFields={shownCardTransactionCustomFields} />
    </Box>
  );
};

export default CardCustomFields;
