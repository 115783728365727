import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGlobalState,
  useSourcePartnerScopeCheck,
} from 'context/GlobalState';
import { isPartnerBasedSource } from 'domains/partner/utils';
import {
  getStaticIntegrationsData,
  isCustomLocoiaAccountingSystem,
} from 'domains/settings/utils';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import useUrls from 'hooks/useUrls';
import {
  AccountingSystem,
  ApiIntegrationStatus,
  IntegrationName,
  PartnerAuthStatus,
  PartnerIds,
  PartnerIdType,
  PartnerOrgAuthDetails,
  PartnerScope,
  PartnerWhitelabelLevel,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import { IntegrationItem } from './index';

const isIntegrationActive = (status: PartnerAuthStatus) =>
  status === PartnerAuthStatus.active;

interface State {
  candisPartnerDetails: PartnerOrgAuthDetails | null;
  circulaPartnerDetails: PartnerOrgAuthDetails | null;
  circulaDemoPartnerDetails: PartnerOrgAuthDetails | null;
  mobilexpensePartnerDetails: PartnerOrgAuthDetails | null;
  mobilexpenseMxpPartnerDetails: PartnerOrgAuthDetails | null;
  bezalaPartnerDetails: PartnerOrgAuthDetails | null;
  klippaPartnerDetails: PartnerOrgAuthDetails | null;
  mocoappPartnerDetails: PartnerOrgAuthDetails | null;
  voxelPartnerDetails: PartnerOrgAuthDetails | null;
  confermaPartnerDetails: PartnerOrgAuthDetails | null;
  basPartnerDetails: PartnerOrgAuthDetails | null;
  scopevisioPartnerDetails: PartnerOrgAuthDetails | null;
  isLoading: boolean;
}

export const useIntegrationsList = () => {
  const { t, i18n } = useTranslation();
  const api = useImperativeApi();
  const showPageError = useShowPageError();
  const mounted = useMounted();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mobilexpenseMxpEnabled,
    voxelEnabled,
    confermaEnabled,
    basEnabled,
    scopevisioEnabled,
  } = useFlags();
  const {
    state: {
      organization,
      accountingSettings,
      partners,
      partnerConfig,
      featureModules,
      subscriptionPlan,
    },
    dispatch,
  } = useGlobalState();
  const { HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL } = useUrls();
  const sourcePartnerIsCustomer = useSourcePartnerScopeCheck(
    PartnerScope.customer
  );
  const isFullyWhitelabelPartner =
    partnerConfig?.whitelabelLevel === PartnerWhitelabelLevel.FULLY_WHITELABEL;
  const [state, setState] = useState<State>({
    candisPartnerDetails: null,
    circulaPartnerDetails: null,
    circulaDemoPartnerDetails: null,
    mobilexpensePartnerDetails: null,
    mobilexpenseMxpPartnerDetails: null,
    bezalaPartnerDetails: null,
    klippaPartnerDetails: null,
    mocoappPartnerDetails: null,
    voxelPartnerDetails: null,
    confermaPartnerDetails: null,
    basPartnerDetails: null,
    scopevisioPartnerDetails: null,
    isLoading: false,
  });

  const orgHasNoSourcePartner = !isPartnerBasedSource(organization!.partnerId);

  const canViewAllTiles =
    orgHasNoSourcePartner ||
    sourcePartnerIsCustomer ||
    isFullyWhitelabelPartner;
  const accountingFeaturesEnabled = featureModules.ACCOUNTING_FEATURES;

  const integrationsStaticData = getStaticIntegrationsData(t, i18n, {
    HELP_CENTER_EXPORTING_TRANSACTIONS_LEXOFFICE_URL,
  });

  const orgHasStarterPlanEnabled =
    canViewAllTiles && !subscriptionPlan?.premiumFeaturesEnabled;

  // all integrations will be returned from BE in near future
  const getData = useCallback(async () => {
    // find and assign partner from Partners array
    const assignPartner = (partnerId: PartnerIdType) => {
      return canUser('partners:view') && partners?.length
        ? partners?.find((item) => item.partnerId === partnerId)
        : undefined;
    };

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const candisPartner = assignPartner(PartnerIds.candis);
    const mobilexpensePartner = assignPartner(PartnerIds.mobilexpenseDeclaree);
    const mobilexpenseMxpPartner = assignPartner(PartnerIds.mobilexpenseMXP);
    const circulaPartner = assignPartner(PartnerIds.circula);
    const circulaDemoPartner = assignPartner(PartnerIds.circulaDemo);
    const bezalaPartner = assignPartner(PartnerIds.bezala);
    const klippaPartner = assignPartner(PartnerIds.klippa);
    const mocoappPartner = assignPartner(PartnerIds.mocoapp);
    const voxelPartner = assignPartner(PartnerIds.voxel);
    const confermaPartner = assignPartner(PartnerIds.conferma);
    const basPartner = assignPartner(PartnerIds.bas);
    const scopevisioPartner = assignPartner(PartnerIds.scopevisio);

    try {
      const [
        accountingSettings,
        circulaPartnerDetails,
        circulaDemoPartnerDetails,
        candisPartnerDetails,
        mobilexpensePartnerDetails,
        mobilexpenseMxpPartnerDetails,
        bezalaPartnerDetails,
        klippaPartnerDetails,
        mocoappPartnerDetails,
        voxelPartnerDetails,
        scopevisioPartnerDetails,
      ] = await Promise.all([
        canUser('accounting-settings:view')
          ? api.getAccountingSettings(organization!.id)
          : null,
        circulaPartner
          ? api.getPartnerOrgAuthDetails(
              circulaPartner.partnerId,
              organization!.id
            )
          : null,
        circulaDemoPartner
          ? api.getPartnerOrgAuthDetails(
              circulaDemoPartner.partnerId,
              organization!.id
            )
          : null,
        candisPartner
          ? api.getPartnerOrgAuthDetails(
              candisPartner.partnerId,
              organization!.id
            )
          : null,
        mobilexpensePartner
          ? api.getPartnerOrgAuthDetails(
              mobilexpensePartner.partnerId,
              organization!.id
            )
          : null,
        mobilexpenseMxpPartner && mobilexpenseMxpEnabled
          ? api.getPartnerOrgAuthDetails(
              mobilexpenseMxpPartner.partnerId,
              organization!.id
            )
          : null,
        bezalaPartner
          ? api.getPartnerOrgAuthDetails(
              bezalaPartner.partnerId,
              organization!.id
            )
          : null,
        klippaPartner
          ? api.getPartnerOrgAuthDetails(
              klippaPartner.partnerId,
              organization!.id
            )
          : null,
        mocoappPartner
          ? api.getPartnerOrgAuthDetails(
              mocoappPartner.partnerId,
              organization!.id
            )
          : null,
        voxelPartner && voxelEnabled
          ? api.getPartnerOrgAuthDetails(
              voxelPartner.partnerId,
              organization!.id
            )
          : null,
        scopevisioPartner && scopevisioEnabled
          ? api.getPartnerOrgAuthDetails(
              scopevisioPartner.partnerId,
              organization!.id
            )
          : null,
      ]);

      const [confermaPartnerDetails, basPartnerDetails] = await Promise.all([
        confermaPartner && confermaEnabled
          ? api.getPartnerOrgAuthDetails(
              confermaPartner.partnerId,
              organization!.id
            )
          : null,
        basPartner && basEnabled
          ? api.getPartnerOrgAuthDetails(basPartner.partnerId, organization!.id)
          : null,
      ]);

      // update organization partner (source)
      if (canUser('partners:view')) {
        const orgPartner = [
          circulaPartnerDetails,
          circulaDemoPartnerDetails,
          candisPartnerDetails,
          mobilexpensePartnerDetails,
          mobilexpenseMxpPartnerDetails,
          klippaPartnerDetails,
          mocoappPartnerDetails,
          voxelPartnerDetails,
          confermaPartnerDetails,
          basPartnerDetails,
          scopevisioPartnerDetails,
        ].find((item) => item?.partnerId === organization!.partnerId);
        if (orgPartner)
          dispatch({
            type: 'SET_ORGANIZATION_DATA',
            payload: { partnerOrgAuthDetails: orgPartner },
          });
      }

      if (accountingSettings) {
        dispatch({
          type: 'SET_USER_DATA',
          payload: { accountingSettings },
        });
      }

      if (!mounted.current) return;

      setState((prevState) => ({
        ...prevState,
        candisPartnerDetails,
        circulaPartnerDetails,
        circulaDemoPartnerDetails,
        mobilexpensePartnerDetails,
        mobilexpenseMxpPartnerDetails,
        bezalaPartnerDetails,
        klippaPartnerDetails,
        mocoappPartnerDetails,
        voxelPartnerDetails,
        confermaPartnerDetails,
        basPartnerDetails,
        scopevisioPartnerDetails,
        isLoading: false,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, []);

  useEffect(() => {
    if (!canUser('accounting-settings:view')) return;
    getData();
  }, []);

  const refetchPartner = async (partnerId: PartnerIdType) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const updatedPartner = await api.getPartnerOrgAuthDetails(
        partnerId,
        organization!.id
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        candisPartnerDetails:
          prevState.candisPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.candisPartnerDetails,
        circulaPartnerDetails:
          prevState.circulaPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.circulaPartnerDetails,
        circulaDemoPartnerDetails:
          prevState.circulaDemoPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.circulaDemoPartnerDetails,
        mobilexpensePartnerDetails:
          prevState.mobilexpensePartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.mobilexpensePartnerDetails,
        mobilexpenseMxpPartnerDetails:
          prevState.mobilexpenseMxpPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.mobilexpenseMxpPartnerDetails,
        bezalaPartnerDetails:
          prevState.bezalaPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.bezalaPartnerDetails,
        klippaPartnerDetails:
          prevState.klippaPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.klippaPartnerDetails,
        mocoappPartnerDetails:
          prevState.mocoappPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.mocoappPartnerDetails,
        voxelPartnerDetails:
          prevState.voxelPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.voxelPartnerDetails,
        confermaPartnerDetails:
          prevState.confermaPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.confermaPartnerDetails,
        basPartnerDetails:
          prevState.basPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.basPartnerDetails,
        scopevisioPartnerDetails:
          prevState.scopevisioPartnerDetails?.partnerId === partnerId
            ? updatedPartner
            : prevState.scopevisioPartnerDetails,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  const getIntegrations = (): IntegrationItem[] => {
    const apiConnected =
      accountingSettings?.useAccountingExport &&
      accountingSettings?.apiIntegrationStatus ===
        ApiIntegrationStatus.connected;

    const circulaStatus =
      state.circulaPartnerDetails?.status || PartnerAuthStatus.inactive;
    const circulsDemoStatus =
      state.circulaDemoPartnerDetails?.status || PartnerAuthStatus.inactive;
    const datevStatus =
      accountingSettings?.accountingSystem === AccountingSystem.datev &&
      accountingSettings?.useAccountingExport
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const lexOfficeStatus =
      accountingSettings?.accountingSystem === AccountingSystem.lexOffice &&
      apiConnected
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const candisStatus =
      state.candisPartnerDetails?.status || PartnerAuthStatus.inactive;
    const mobilexpenseStatus =
      state.mobilexpensePartnerDetails?.status || PartnerAuthStatus.inactive;
    const mobilexpenseMxpStatus =
      state.mobilexpenseMxpPartnerDetails?.status || PartnerAuthStatus.inactive;
    const netvisorStatus =
      accountingSettings?.accountingSystem === AccountingSystem.netvisor &&
      accountingSettings?.useAccountingExport
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const procountorStatus =
      accountingSettings?.accountingSystem === AccountingSystem.procountor &&
      accountingSettings?.useAccountingExport
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const xeroStatus =
      accountingSettings?.accountingSystem === AccountingSystem.xero &&
      apiConnected
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const quickbooksStatus =
      accountingSettings?.accountingSystem === AccountingSystem.quickbooks &&
      apiConnected
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const oracleNetSuiteStatus =
      accountingSettings?.accountingSystem ===
        AccountingSystem.oracleNetSuite && apiConnected
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const microsoftDynamicsStatus =
      accountingSettings?.accountingSystem ===
        AccountingSystem.microsoftDynamics365BusinessCentral && apiConnected
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;
    const klippaStatus =
      state.klippaPartnerDetails?.status || PartnerAuthStatus.inactive;
    const mocoappStatus =
      state.mocoappPartnerDetails?.status || PartnerAuthStatus.inactive;
    const voxelStatus =
      state.voxelPartnerDetails?.status || PartnerAuthStatus.inactive;
    const confermaStatus =
      state.confermaPartnerDetails?.status || PartnerAuthStatus.inactive;
    const basStatus =
      state.basPartnerDetails?.status || PartnerAuthStatus.inactive;
    const scopevisioStatus =
      state.scopevisioPartnerDetails?.status || PartnerAuthStatus.inactive;

    const customCsvStatus =
      isCustomLocoiaAccountingSystem(accountingSettings) &&
      accountingSettings?.apiIntegrationStatus ===
        ApiIntegrationStatus.connected
        ? PartnerAuthStatus.active
        : PartnerAuthStatus.inactive;

    const integrationsArray = [
      {
        name: IntegrationName.circula,
        partnerId: PartnerIds.circula,
        ...integrationsStaticData[PartnerIds.circula],
        status: circulaStatus,
        visible:
          canViewAllTiles ||
          organization!.partnerId === state.circulaPartnerDetails?.partnerId ||
          isIntegrationActive(circulaStatus),
        partnerDetails: state.circulaPartnerDetails,
      },
      ...(state.circulaDemoPartnerDetails
        ? [
            {
              name: IntegrationName.circula,
              partnerId: PartnerIds.circulaDemo,
              ...integrationsStaticData[PartnerIds.circula],
              title: `${t('integrationsPage.circula.title')} (Demo)`,
              status: circulsDemoStatus,
              visible:
                canViewAllTiles ||
                organization!.partnerId ===
                  state.circulaDemoPartnerDetails.partnerId ||
                isIntegrationActive(circulsDemoStatus),
              partnerDetails: state.circulaDemoPartnerDetails,
            },
          ]
        : []),
      {
        name: IntegrationName.datev,
        ...integrationsStaticData[IntegrationName.datev],
        status: datevStatus,
        visible:
          accountingFeaturesEnabled &&
          (canViewAllTiles || isIntegrationActive(datevStatus)),
        partnerDetails: null,
      },
      {
        name: IntegrationName.tidely,
        ...integrationsStaticData[IntegrationName.tidely],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.buchhaltungsButler,
        ...integrationsStaticData[IntegrationName.buchhaltungsButler],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.candis,
        partnerId: PartnerIds.candis,
        ...integrationsStaticData[PartnerIds.candis],
        status: candisStatus,
        visible:
          canUser('partners:view') &&
          (canViewAllTiles ||
            isIntegrationActive(candisStatus) ||
            organization!.partnerId === state.candisPartnerDetails?.partnerId),
        partnerDetails: state.candisPartnerDetails,
      },
      {
        name: IntegrationName.getMyInvoices,
        ...integrationsStaticData[IntegrationName.getMyInvoices],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.flowwer,
        ...integrationsStaticData[IntegrationName.flowwer],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.kanzleiland,
        ...integrationsStaticData[IntegrationName.kanzleiland],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.bookman,
        ...integrationsStaticData[IntegrationName.bookman],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.hellohq,
        ...integrationsStaticData[IntegrationName.hellohq],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.mobilexpense,
        partnerId: PartnerIds.mobilexpenseDeclaree,
        ...integrationsStaticData[PartnerIds.mobilexpenseDeclaree],
        status:
          state.mobilexpensePartnerDetails?.status ||
          PartnerAuthStatus.inactive,
        visible:
          canUser('partners:view') &&
          (canViewAllTiles ||
            isIntegrationActive(mobilexpenseStatus) ||
            organization!.partnerId ===
              state.mobilexpensePartnerDetails?.partnerId),
        partnerDetails: state.mobilexpensePartnerDetails,
      },
      {
        name: IntegrationName.mobilexpenseMXP,
        partnerId: PartnerIds.mobilexpenseMXP,
        ...integrationsStaticData[PartnerIds.mobilexpenseMXP],
        status:
          state.mobilexpenseMxpPartnerDetails?.status ||
          PartnerAuthStatus.inactive,
        visible:
          mobilexpenseMxpEnabled &&
          canUser('partners:view') &&
          (canViewAllTiles ||
            isIntegrationActive(mobilexpenseMxpStatus) ||
            organization!.partnerId ===
              state.mobilexpenseMxpPartnerDetails?.partnerId),
        partnerDetails: state.mobilexpenseMxpPartnerDetails,
      },
      {
        name: IntegrationName.domonda,
        ...integrationsStaticData[IntegrationName.domonda],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.rydoo,
        ...integrationsStaticData[IntegrationName.rydoo],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.commitly,
        ...integrationsStaticData[IntegrationName.commitly],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.freeFinance,
        ...integrationsStaticData[IntegrationName.freeFinance],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.agicap,
        ...integrationsStaticData[IntegrationName.agicap],
        status: PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: null,
      },
      {
        name: IntegrationName.mocoapp,
        partnerId: PartnerIds.mocoapp,
        ...integrationsStaticData[PartnerIds.mocoapp],
        status: mocoappStatus,
        visible:
          canViewAllTiles ||
          organization!.partnerId === state.mocoappPartnerDetails?.partnerId ||
          isIntegrationActive(mocoappStatus),
        partnerDetails: state.mocoappPartnerDetails,
      },
      {
        name: IntegrationName.lexOffice,
        ...integrationsStaticData[IntegrationName.lexOffice],
        status: lexOfficeStatus,
        visible:
          accountingFeaturesEnabled &&
          (canViewAllTiles || isIntegrationActive(lexOfficeStatus)),
        partnerDetails: null,
      },
      {
        name: IntegrationName.bezala,
        partnerId: PartnerIds.bezala,
        ...integrationsStaticData[PartnerIds.bezala],
        status:
          state.bezalaPartnerDetails?.status || PartnerAuthStatus.inactive,
        visible: canViewAllTiles,
        partnerDetails: state.bezalaPartnerDetails,
      },
      {
        name: IntegrationName.customCSV,
        ...integrationsStaticData[IntegrationName.customCSV],
        // Add appropriate status when we have it
        status: customCsvStatus,
        visible: canViewAllTiles || isIntegrationActive(customCsvStatus),
        partnerDetails: null,
      },
      {
        name: IntegrationName.netvisor,
        ...integrationsStaticData[IntegrationName.netvisor],
        status: netvisorStatus,
        visible: canViewAllTiles || isIntegrationActive(netvisorStatus),
        partnerDetails: null,
      },
      {
        name: IntegrationName.procountor,
        ...integrationsStaticData[IntegrationName.procountor],
        status: procountorStatus,
        visible: canViewAllTiles || isIntegrationActive(procountorStatus),
        partnerDetails: null,
      },
      {
        name: IntegrationName.xero,
        ...integrationsStaticData[IntegrationName.xero],
        status: xeroStatus,
        visible:
          accountingFeaturesEnabled &&
          (canViewAllTiles || isIntegrationActive(xeroStatus)),
        partnerDetails: null,
      },
      {
        name: IntegrationName.quickbooks,
        ...integrationsStaticData[IntegrationName.quickbooks],
        status: quickbooksStatus,
        visible:
          accountingFeaturesEnabled &&
          (canViewAllTiles || isIntegrationActive(quickbooksStatus)),
        partnerDetails: null,
      },
      {
        name: IntegrationName.oracleNetSuite,
        ...integrationsStaticData[IntegrationName.oracleNetSuite],
        status: oracleNetSuiteStatus,
        visible:
          accountingFeaturesEnabled &&
          (canViewAllTiles || isIntegrationActive(oracleNetSuiteStatus)),
        partnerDetails: null,
      },
      {
        name: IntegrationName.microsoftDynamics365BusinessCentral,
        ...integrationsStaticData[
          IntegrationName.microsoftDynamics365BusinessCentral
        ],
        status: microsoftDynamicsStatus,
        visible:
          accountingFeaturesEnabled &&
          (canViewAllTiles || isIntegrationActive(microsoftDynamicsStatus)),
        partnerDetails: null,
      },
      {
        name: IntegrationName.klippa,
        partnerId: PartnerIds.klippa,
        ...integrationsStaticData[PartnerIds.klippa],
        status: klippaStatus,
        visible:
          canViewAllTiles ||
          organization!.partnerId === state.klippaPartnerDetails?.partnerId ||
          isIntegrationActive(klippaStatus),
        partnerDetails: state.klippaPartnerDetails,
      },
      {
        name: IntegrationName.voxel,
        partnerId: PartnerIds.voxel,
        ...integrationsStaticData[PartnerIds.voxel],
        status: voxelStatus,
        visible:
          voxelEnabled &&
          (canViewAllTiles ||
            organization!.partnerId === state.voxelPartnerDetails?.partnerId ||
            isIntegrationActive(voxelStatus)),
        partnerDetails: state.voxelPartnerDetails,
      },
      {
        name: IntegrationName.conferma,
        partnerId: PartnerIds.conferma,
        ...integrationsStaticData[PartnerIds.conferma],
        status: confermaStatus,
        visible:
          confermaEnabled &&
          (canViewAllTiles ||
            organization!.partnerId ===
              state.confermaPartnerDetails?.partnerId ||
            isIntegrationActive(confermaStatus)),
        partnerDetails: state.confermaPartnerDetails,
      },
      {
        name: IntegrationName.bas,
        partnerId: PartnerIds.bas,
        ...integrationsStaticData[PartnerIds.bas],
        status: basStatus,
        visible:
          basEnabled &&
          (canViewAllTiles ||
            organization!.partnerId === state.basPartnerDetails?.partnerId ||
            isIntegrationActive(basStatus)),
        partnerDetails: state.basPartnerDetails,
      },
      {
        name: IntegrationName.scopevisio,
        partnerId: PartnerIds.scopevisio,
        ...integrationsStaticData[PartnerIds.scopevisio],
        status: scopevisioStatus,
        visible:
          scopevisioEnabled &&
          (canViewAllTiles ||
            organization!.partnerId ===
              state.scopevisioPartnerDetails?.partnerId ||
            isIntegrationActive(scopevisioStatus)),
        partnerDetails: state.scopevisioPartnerDetails,
      },
    ];

    return integrationsArray.map((item) => ({
      ...item,
      requiresUpgrade:
        item.isPremium &&
        orgHasStarterPlanEnabled &&
        item.status === PartnerAuthStatus.inactive,
    }));
  };

  return {
    isLoading: state.isLoading,
    integrations: getIntegrations(),
    refetchPartner,
  };
};
