import { EUR } from '@dinero.js/currencies';

export enum MemberTitle {
  mr = 'MR',
  mrs = 'MRS',
}

export const memberTitles = [MemberTitle.mr, MemberTitle.mrs];

export enum MembershipStatus {
  invited = 'INVITED',
  active = 'ACTIVE',
  deactivated = 'DEACTIVATED',
}

export enum MemberStatus {
  invited = 'INVITED',
  active = 'ACTIVE',
  deactivated = 'DEACTIVATED',
}

export const memberStatuses = [
  MemberStatus.invited,
  MemberStatus.active,
  MemberStatus.deactivated,
];

export enum LegalRepresentativeType {
  sole = 'SOLE',
  joint = 'JOINT',
  none = 'NONE',
}

export const legalRepresentativeTypes = Object.values(LegalRepresentativeType);

export enum TeamStatus {
  active = 'ACTIVE',
  deactivated = 'DEACTIVATED',
}

export const teamStatuses = Object.values(TeamStatus);

export enum AccountGroup {
  vgTpml = 'VG_TPML',
  bcPliantOy = 'BC_PLIANT_OY',
  vgPliantOy = 'VG_PLIANT_OY',
}

export const accountGroups = Object.values(AccountGroup);

export enum CardAccountCurrency {
  EUR = 'EUR',
  CHF = 'CHF',
  CZK = 'CZK',
  DKK = 'DKK',
  GBP = 'GBP',
  HUF = 'HUF',
  NOK = 'NOK',
  PLN = 'PLN',
  RON = 'RON',
  SEK = 'SEK',
  USD = 'USD',
}

export const CURRENCY_NAME_BY_CODE: { [K in CardAccountCurrency]: string } = {
  EUR: 'Euro',
  CHF: 'Swiss Frank',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  GBP: 'Great British Pound',
  HUF: 'Hungarian Forint',
  NOK: 'Norwegian Krone',
  PLN: 'Polish złoty',
  RON: 'Romanian Leu',
  SEK: 'Swedish Krona',
  USD: 'US Dollar',
} as const;

export enum OrganizationStatus {
  onboarding = 'ONBOARDING', // required for new onboarding flow
  onboardingDetails = 'ONBOARDING_DETAILS',
  onboardingRiskData = 'ONBOARDING_RISK_DATA',
  onboardingRiskReview = 'ONBOARDING_RISK_REVIEW',
  onboardingCompliance = 'ONBOARDING_COMPLIANCE',
  onboardingDirectDebit = 'ONBOARDING_DIRECT_DEBIT',
  onboardingWait = 'ONBOARDING_WAIT',
  active = 'ACTIVE',
  deactivated = 'DEACTIVATED',
  cancelled = 'CANCELLED',
  nonCustomer = 'NON_CUSTOMER',
}

// deprecated order - onboardingDirectDebit & onboardingCompliance are parallel and not sequential anymore
export const organizationOnboardingStatuses = [
  OrganizationStatus.onboarding,
  OrganizationStatus.onboardingDetails,
  OrganizationStatus.onboardingRiskData,
  OrganizationStatus.onboardingRiskReview,
  OrganizationStatus.onboardingCompliance,
  OrganizationStatus.onboardingDirectDebit,
  OrganizationStatus.onboardingWait,
];
export const organizationStatuses = [
  ...organizationOnboardingStatuses,
  OrganizationStatus.active,
  OrganizationStatus.deactivated,
  OrganizationStatus.cancelled,
  OrganizationStatus.nonCustomer,
];

export enum OrganizationAccountType {
  credit = 'CREDIT',
  prefunded = 'PREFUNDED',
}

export enum CardAccountStatus {
  active = 'ACTIVE',
  cancelled = 'CANCELLED',
  deactivated = 'DEACTIVATED',
  pending = 'PENDING',
}

export enum ProcessingAccountTransferType {
  local = 'LOCAL',
  swift = 'SWIFT',
}

export enum OrganizationProductType {
  MDESD = 'MDESD',
  CCROY = 'CCROY',
  CCREU = 'CCREU',
}

export enum PaymentFrequencyType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  MONTHLY = 'MONTHLY',
}

export const paymentFrequencyTypes = [
  PaymentFrequencyType.MONTHLY,
  PaymentFrequencyType.SEMI_MONTHLY,
  PaymentFrequencyType.BI_WEEKLY,
  PaymentFrequencyType.WEEKLY,
  PaymentFrequencyType.DAILY,
];

export enum FeatureModuleKey {
  accountingFeatures = 'ACCOUNTING_FEATURES',
  accountingMerchantRules = 'ACCOUNTING_MERCHANT_RULES',
  cardControls = 'CARD_CONTROLS',
  cardholderManagement = 'CARDHOLDER_MANAGEMENT',
  cardRequests = 'CARD_REQUESTS',
  cashback = 'CASHBACK',
  customCardholderName = 'CUSTOM_CARDHOLDER_NAME',
  logoCards = 'LOGO_CARDS',
  singleUseCards = 'SINGLE_USE_CARDS',
  teams = 'TEAMS',
  teamBudgets = 'TEAM_BUDGETS',
  travelPurchasingCards = 'TRAVEL_PURCHASING_CARDS',
  pliantEarth = 'PLIANT_EARTH',
  monthlyTransactionReport = 'MONTHLY_TRANSACTION_REPORT',
  multiCardAccount = 'MULTI_CARD_ACCOUNT',
  multiCurrencyBilling = 'MULTI_CURRENCY_BILLING',
  receiptManagement = 'RECEIPT_MANAGEMENT',
  managerTxReviews = 'MANAGER_TX_REVIEWS',
  integrationsPage = 'INTEGRATIONS_PAGE',
  transactionCustomFields = 'TRANSACTION_CUSTOM_FIELDS',
  memberManagement = 'MEMBER_MANAGEMENT',
  securityKeyAuthentication = 'SECURITY_KEY_AUTHENTICATION',
  privateExpense = 'PRIVATE_EXPENSE',
}

export const featureModuleKeys = Object.values(FeatureModuleKey);

export enum CardType {
  black = 'BLACK',
  plastic = 'PHYSICAL',
  virtual = 'VIRTUAL',
  singleUse = 'SINGLE_USE',
}

export const EXPIRY_PERIODS = [3, 6, 9, 12, 18, 24, 30, 36];

export const SINGLE_USE_EXPIRY_PERIODS = [12];

export const cardTypes = [
  CardType.virtual,
  CardType.singleUse,
  CardType.plastic,
  CardType.black,
];

export enum CardConfig {
  candisBlue = 'CANDIS_BLUE',
  candisSingleUse = 'CANDIS_SINGLE_USE',
  candisVirtual = 'CANDIS_VIRTUAL',
  circulaBlue = 'CIRCULA_BLUE',
  mobilexpenseBlue = 'MOBILEXPENSE_BLUE',
  mobilexpenseVirtual = 'MOBILEXPENSE_VIRTUAL',
  pliantBlack = 'PLIANT_BLACK',
  pliantBlue = 'PLIANT_BLUE',
  pliantSingleUse = 'PLIANT_SINGLE_USE',
  pliantVirtual = 'PLIANT_VIRTUAL',
  pliantVirtualTravel = 'PLIANT_VIRTUAL_TRAVEL',
  commercialChoice100 = 'PLIANT_VIRTUAL_TRAVEL_CC_100',
  commercialChoice120 = 'PLIANT_VIRTUAL_TRAVEL_CC_120',
  commercialChoice140 = 'PLIANT_VIRTUAL_TRAVEL_CC_140',
  commercialChoice150 = 'PLIANT_VIRTUAL_TRAVEL_CC_150',
  commercialChoice160 = 'PLIANT_VIRTUAL_TRAVEL_CC_160',
  commercialChoice180 = 'PLIANT_VIRTUAL_TRAVEL_CC_180',
  commercialChoice200 = 'PLIANT_VIRTUAL_TRAVEL_CC_200',
  commercialChoice = 'PLIANT_VIRTUAL_TRAVEL_CC',
}

export enum CardConfigGroup {
  candisBlue = 'CANDIS_BLUE',
  candisSingleUse = 'CANDIS_SINGLE_USE',
  candisVirtual = 'CANDIS_VIRTUAL',
  circulaBlue = 'CIRCULA_BLUE',
  mobilexpenseBlue = 'MOBILEXPENSE_BLUE',
  mobilexpenseVirtual = 'MOBILEXPENSE_VIRTUAL',
  pliantBlack = 'PLIANT_BLACK',
  pliantBlue = 'PLIANT_BLUE',
  pliantSingleUse = 'PLIANT_SINGLE_USE',
  pliantVirtual = 'PLIANT_VIRTUAL',
  pliantVirtualTravel = 'PLIANT_VIRTUAL_TRAVEL',
}

export enum CardNewType {
  physical = 'PHYSICAL',
  virtual = 'VIRTUAL',
}

export enum CardPremiumProductType {
  visaInfinite = 'VISA_INFINITE',
}

export enum CardControlRestriction {
  allowed = 'ALLOWED',
  blocked = 'BLOCKED',
}

export const cardControlRestrictions = Object.values(CardControlRestriction);

export enum CardCategoryControlType {
  category = 'CATEGORY',
  mcc = 'MCC',
}

export const cardCategoryControlTypes = Object.values(CardCategoryControlType);

export enum CardMerchantControlType {
  matchTerm = 'MATCH_TERM',
  mid = 'MID',
}

export const cardMerchantControlTypes = Object.values(CardMerchantControlType);

export enum CardDateControlType {
  fixed = 'FIXED',
  yearly = 'YEARLY',
  monthly = 'MONTHLY',
  weekly = 'WEEKLY',
}

export const cardDateControlTypes = Object.values(CardDateControlType);

export enum CardTimeControlType {
  timerange = 'TIMERANGE',
}

export const cardTimeControlTypes = Object.values(CardTimeControlType);

export enum CardLocationControlType {
  postalCode = 'POSTAL_CODE',
}

export const cardLocationControlTypes = Object.values(CardLocationControlType);

export enum CardStatus {
  active = 'ACTIVE',
  expired = 'EXPIRED',
  requested = 'REQUESTED',
  pendingRegistration = 'PENDING_REGISTRATION',
  pendingIssuance = 'PENDING_ISSUANCE',
  pendingActivation = 'PENDING_ACTIVATION',
  pendingValidity = 'PENDING_VALIDITY',
  rejected = 'REJECTED',
  locked = 'LOCKED',
  lockedByDateTimeCardControls = 'LOCKED_CC_DATETIME',
  lockedPin = 'LOCKED_PIN',
  terminated = 'TERMINATED',
  terminatedLost = 'TERMINATED_LOST',
  terminatedStolen = 'TERMINATED_STOLEN',
  requestCancelled = 'REQUEST_CANCELLED',
}

export enum CardLimitChangeRequestStatus {
  approved = 'APPROVED',
  rejected = 'REJECTED',
  pending = 'PENDING',
  canceled = 'CANCELED',
}

export enum CardLockReason {
  lockedByDatesCardControl = 'LOCKED_BY_DATE_CONTROL',
  lockedByDatesAndTimesCardControl = 'LOCKED_BY_DATE_AND_TIME_CONTROL',
  lockedByTimesCardControl = 'LOCKED_BY_TIME_CONTROL',
}

export enum CardSimpleStatus {
  active = 'ACTIVE',
  expired = 'EXPIRED',
  pending = 'PENDING',
  locked = 'LOCKED',
  terminated = 'TERMINATED',
  requested = 'REQUESTED',
}

export const cardSimpleStatuses = [
  CardSimpleStatus.requested,
  CardSimpleStatus.pending,
  CardSimpleStatus.active,
  CardSimpleStatus.locked,
  CardSimpleStatus.terminated,
  CardSimpleStatus.expired,
];

// Simple card statuses which will be requested
// for a member on wallet and member details pages
export const memberCardStatusesToRequest = [
  CardSimpleStatus.active,
  CardSimpleStatus.pending,
  CardSimpleStatus.requested,
  CardSimpleStatus.locked,
];

export enum CardDesignLayout {
  physical = 'PHYSICAL_LAYOUT',
  virtual = 'VIRTUAL_LAYOUT',
  partnerVirtual = 'PARTNER_VIRTUAL_LAYOUT',
}

export enum CardIssuanceUnavailableReason {
  memberLimitReached = 'MEMBER_LIMIT_REACHED',
  organizationLimitReached = 'ORGANIZATION_LIMIT_REACHED',
  notConfigured = 'NOT_CONFIGURED',
}

export enum CardReplacementReason {
  compromised = 'COMPROMISED',
  damaged = 'DAMAGED',
  expiringSoon = 'EXPIRING_SOON',
  lost = 'LOST',
  other = 'OTHER',
  stolen = 'STOLEN',
}

export const physicalCardReplacementReasons = [
  CardReplacementReason.lost,
  CardReplacementReason.stolen,
  CardReplacementReason.expiringSoon,
  CardReplacementReason.damaged,
  CardReplacementReason.other,
];

export const virtualCardReplacementReasons = [
  CardReplacementReason.compromised,
  CardReplacementReason.expiringSoon,
  CardReplacementReason.other,
];

export enum CardLimitRenewFrequency {
  monthly = 'MONTHLY',
  quarterly = 'QUARTERLY',
  annual = 'ANNUAL',
  total = 'TOTAL',
}

export const cardLimitRenewFrequencies = Object.values(CardLimitRenewFrequency);

export enum CardManufacturer {
  exceet = 'EXCEET',
  idemia = 'IDEMIA',
}

export enum CardTerminationReason {
  detailsCompromised = 'DETAILS_COMPROMISED',
  lost = 'LOST',
  stolen = 'STOLEN',
  notNeeded = 'OTHER',
}

export const physicalCardTerminationReasons = [
  CardTerminationReason.notNeeded,
  CardTerminationReason.lost,
  CardTerminationReason.stolen,
  CardTerminationReason.detailsCompromised,
];

export const virtualCardTerminationReasons = [
  CardTerminationReason.notNeeded,
  CardTerminationReason.stolen,
];

export enum CardHistoryEntryType {
  created = 'CREATED',
  issued = 'ISSUED',
  activated = 'ACTIVATED',
  expired = 'EXPIRED',
  locked = 'LOCKED',
  lockedPin = 'LOCKED_PIN',
  unlocked = 'UNLOCKED',
  limitChanged = 'LIMIT_CHANGED',
  transactionLimitChanged = 'TRANSACTION_LIMIT_CHANGED',
  pinChanged = 'PIN_CHANGED',
  teamUpdated = 'TEAM_UPDATED',
  terminatedLost = 'TERMINATED_LOST',
  terminatedStolen = 'TERMINATED_STOLEN',
  terminatedNotNeeded = 'TERMINATED_NOT_NEEDED',
  cardLimitChangeRequestCanceled = 'CARD_LIMIT_CHANGE_REQUEST_CANCELED',
  cardLimitChangeRequestCreated = 'CARD_LIMIT_CHANGE_REQUEST_CREATED',
  cardLimitChangeRequestRejected = 'CARD_LIMIT_CHANGE_REQUEST_REJECTED',
  cardTransactionLimitChangeRequestCanceled = 'CARD_TRANSACTION_LIMIT_CHANGE_REQUEST_CANCELED',
  cardTransactionLimitChangeRequestCreated = 'CARD_TRANSACTION_LIMIT_CHANGE_REQUEST_CREATED',
  cardTransactionLimitChangeRequestRejected = 'CARD_TRANSACTION_LIMIT_CHANGE_REQUEST_REJECTED',
  requested = 'REQUESTED',
  requestCancelled = 'REQUEST_CANCELLED',
  requestRejected = 'REQUEST_REJECTED',
  requestApproved = 'REQUEST_APPROVED',
  replacedByCard = 'REPLACED_BY_CARD',
  replaced = 'REPLACED_CARD',
  shipped = 'SHIPPED',
  cardColorChanged = 'CARD_COLOR_CHANGED',
  cardControlCategories = 'CARD_CONTROL_CATEGORIES',
  cardControlDates = 'CARD_CONTROL_DATES',
  cardControlMerchants = 'CARD_CONTROL_MERCHANTS',
  cardControlTimes = 'CARD_CONTROL_TIMES',
  cardControlLocations = 'CARD_CONTROL_LOCATIONS',
  validityFromChanged = 'VALIDITY_FROM_CHANGED',
  validityToChanged = 'VALIDITY_TO_CHANGED',
  validityTimezoneChanged = 'VALIDITY_TIMEZONE_CHANGED',
}

export const cardHistoryEntryTypes = Object.values(CardHistoryEntryType);

export enum TransactionStatus {
  pending = 'PENDING',
  declined = 'DECLINED',
  reversed = 'REVERSED',
  confirmed = 'CONFIRMED',
}

export const transactionStatuses = Object.values(TransactionStatus);

export enum TransactionSimpleType {
  purchase = 'PURCHASE',
  cashWithdrawal = 'CASH_WITHDRAWAL',
  refund = 'REFUND',
  recharge = 'RECHARGE',
  chargeback = 'CHARGEBACK',
  statusInquiry = 'STATUS_INQUIRY',
}

export enum MerchantCategory {
  advertisingAndMarketing = 'ADVERTISING_AND_MARKETING',
  computingAndSoftware = 'COMPUTING_AND_SOFTWARE',
  educationAndTraining = 'EDUCATION_AND_TRAINING',
  electronicsAndItEquipment = 'ELECTRONICS_AND_IT_EQUIPMENT',
  entertainmentAndWellness = 'ENTERTAINMENT_AND_WELLNESS',
  foodAndDrinks = 'FOOD_AND_DRINKS',
  giftsAndVouchers = 'GIFTS_AND_VOUCHERS',
  materialsAndPackaging = 'MATERIALS_AND_PACKAGING',
  officeSuppliesAndEquipment = 'OFFICE_SUPPLIES_AND_EQUIPMENT',
  services = 'SERVICES',
  travelAndAccommodation = 'TRAVEL_AND_ACCOMMODATION',
  other = 'OTHER',
}

export const merchantCategories = [
  MerchantCategory.advertisingAndMarketing,
  MerchantCategory.computingAndSoftware,
  MerchantCategory.educationAndTraining,
  MerchantCategory.electronicsAndItEquipment,
  MerchantCategory.entertainmentAndWellness,
  MerchantCategory.foodAndDrinks,
  MerchantCategory.giftsAndVouchers,
  MerchantCategory.materialsAndPackaging,
  MerchantCategory.officeSuppliesAndEquipment,
  MerchantCategory.services,
  MerchantCategory.travelAndAccommodation,
  MerchantCategory.other,
];

export enum TransactionsDownloadFileType {
  defaultCsv = 'DEFAULT_CSV',
  camt053Xml = 'CAMT_053',
}

// generic status field (Subcategory, VatRate, Project, Supplier)
export enum AccountingItemStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export enum SubcategoryStatus {
  missing = 'MISSING',
  single = 'SINGLE',
  multiple = 'MULTIPLE',
}

export enum VatRateStatus {
  missing = 'MISSING',
  single = 'SINGLE',
  multiple = 'MULTIPLE',
}

export enum ProjectStatus {
  missing = 'MISSING',
  single = 'SINGLE',
  multiple = 'MULTIPLE',
}

export enum ReceiptMimeType {
  png = 'PNG',
  jpg = 'JPG',
  pdf = 'PDF',
}

export enum ReceiptStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  rejected = 'REJECTED',
  rejectedByAccountingSystem = 'REJECTED_BY_ACCOUNTING_SYSTEM',
}

export enum TransactionReceiptStatus {
  missing = 'MISSING',
  uploaded = 'UPLOADED',
  notNeeded = 'NOT_NEEDED',
}

export const transactionReceiptStatuses = Object.values(
  TransactionReceiptStatus
);

export enum TransactionExportStatus {
  notExported = 'NOT_EXPORTED',
  inExportQueue = 'IN_EXPORT_QUEUE',
  exported = 'EXPORTED',
  exporting = 'EXPORTING', // for datev-api
}

export const transactionExportStatuses = Object.values(TransactionExportStatus);

export enum TransactionReviewStatus {
  needsReview = 'NEEDS_REVIEW',
  approved = 'APPROVED',
  flagged = 'FLAGGED',
}

export const transactionReviewStatuses = Object.values(TransactionReviewStatus);

export enum AccountEntryType {
  transaction = 'TRANSACTION',
  billPayment = 'BILL_PAYMENT',
  other = 'OTHER',
}

export enum AccountEntryBillPaymentType {
  bill = 'BILL',
  prefunding = 'PREFUNDING',
  billPaymentReversal = 'BILL_PAYMENT_REVERSAL',
  directDebitReversalFee = 'DIRECT_DEBIT_REVERSAL_FEE',
  payout = 'PAYOUT',
}

export enum BillStatus {
  ongoing = 'ONGOING',
  open = 'OPEN',
  overdue = 'OVERDUE',
  closed = 'CLOSED',
}

export enum PaymentType {
  payout = 'PAYOUT',
  billPmt = 'BILL_PMT',
  billPmtReversal = 'BILL_PMT_REVERSAL',
  orgSetlPmt = 'ORG_SETL_PMT',
  programSetlPmt = 'PROGRAM_SETL_PMT',
  programSetlFeePmt = 'PROGRAM_SETL_FEE_PMT',
  rcnPmt = 'RCN_PMT',
  cashbackRedemption = 'CASHBACK_REDEMPTION',
  receivablePurchasePmt = 'RECEIVABLE_PURCHASE_PMT',
  receivableDiscountPmt = 'RECEIVABLE_DISCOUNT_PMT',
  creditFundingPmt = 'CREDIT_FUNDING_PMT',
  prefundingPmt = 'PREFUNDING_PMT',
  other = 'OTHER',
}

export enum PaymentStatus {
  booked = 'BOOKED',
  planned = 'PLANNED',
  upcoming = 'UPCOMING',
  deferred = 'DEFERRED',
  readyForPmt = 'READY_FOR_PMT',
  pending = 'PENDING',
  paid = 'PAID',
  failed = 'FAILED',
  discarded = 'DISCARDED',
  delayed = 'DELAYED',
}

export const paymentStatuses = Object.values(PaymentStatus);

export enum OriginatorPaymentType {
  payout = 'PAYOUT',
  billPmt = 'BILL_PMT',
  billPmtReversal = 'BILL_PMT_REVERSAL',
  orgSetlPmt = 'ORG_SETL_PMT',
  programSetlPmt = 'PROGRAM_SETL_PMT',
  programSetlFeePmt = 'PROGRAM_SETL_FEE_PMT',
  rcnPmt = 'RCN_PMT',
  cashbackRedemption = 'CASHBACK_REDEMPTION',
  prefundingPmt = 'PREFUNDING_PMT',
  other = 'OTHER',
}

export enum CashbackRedemptionBatchState {
  waiting = 'WAITING',
  submit = 'SUBMIT',
  submitted = 'SUBMITTED',
}

export enum CashbackRedemptionStatus {
  waiting = 'WAITING',
  submit = 'SUBMIT',
  pending = 'PENDING',
  failed = 'FAILED',
  redeemed = 'REDEEMED',
}
export const cashbackRedemptionStatuses = Object.values(
  CashbackRedemptionStatus
);

export enum CashbackRedemptionType {
  payout = 'PAYOUT',
  carbonOffset = 'CARBON_OFFSET',
}

export enum HistoryCashbackRedemptionType {
  payout = 'PAYOUT',
  carbonOffset = 'CARBON_OFFSET',
}

export enum HistoryCashbackRedemptionStatus {
  ongoing = 'ONGOING',
  pending = 'PENDING',
  paid = 'PAID',
}

export enum CompensationBatchStatus {
  ongoing = 'ONGOING',
  waiting = 'WAITING',
  paid = 'PAID',
}

export enum VssType {
  EANSS = 'EANSS',
  ISS = 'ISS',
  NNSS = 'NNSS',
}

export const vssTypes = Object.values(VssType);

export enum OrganizationSettlementStatus {
  confirm = 'CONFIRM',
  pending = 'PENDING',
  resolve = 'RESOLVE',
  settled = 'SETTLED',
}

export const organizationSettlementStatuses = Object.values(
  OrganizationSettlementStatus
);

export enum ProgramSettlementStatus {
  confirm = 'CONFIRM',
  pending = 'PENDING',
  reconcile = 'RECONCILE',
  resolve = 'RESOLVE',
  settled = 'SETTLED',
}

export const programSettlementStatuses = Object.values(ProgramSettlementStatus);

export type SettlementStatus =
  | OrganizationSettlementStatus
  | ProgramSettlementStatus;

export enum SettlementPaymentType {
  gross = 'GROSS',
  net = 'NET',
}

export enum DirectDebitType {
  core = 'CORE',
  b2b = 'B2B',
}

export const directDebitTypes = Object.values(DirectDebitType);

export enum BankAccountTransfersAllowedStatus {
  review = 'REVIEW',
  authorized = 'AUTHORIZED',
  blocked = 'BLOCKED',
}

export enum BankAccountType {
  finapi = 'FINAPI',
  klarna = 'KLARNA',
  manual = 'MANUAL',
}

export enum BillPaymentBatchBillPaymentType {
  billPayment = 'BILL_PAYMENT',
  prefundPayment = 'PREFUND_PAYMENT',
  billPayment_reversal = 'BILL_PAYMENT_REVERSAL',
  directDebitReversalFee = 'DIRECT_DEBIT_REVERSAL_FEE',
  payout = 'PAYOUT',
}

export enum MandateStatus {
  prepared = 'PREPARED',
  confirmed = 'CONFIRMED',
  confirmedPartially = 'CONFIRMED_PARTIALLY',
  inactive = 'INACTIVE',
}

export enum CashbackType {
  general = 'GENERAL',
  fx = 'FX',
  mccLow = 'MCC_LOW',
  mccLowTwo = 'MCC_LOW_TWO',
  mccZero = 'MCC_ZERO',
}

export const cashbackTypes = Object.values(CashbackType);

export enum MonthlyCashbackProgressStatus {
  closed = 'CLOSED',
  ongoing = 'ONGOING',
}

export enum PartialPaymentStatus {
  planned = 'PLANNED',
  upcoming = 'UPCOMING',
  deferred = 'DEFERRED',
  readyForPmt = 'READY_FOR_PMT',
  pending = 'PENDING',
  paid = 'PAID',
  booked = 'BOOKED',
  failed = 'FAILED',
  discarded = 'DISCARDED',
  delayed = 'DELAYED',
  waitingForApproval = 'WAITING_FOR_APPROVAL',
  reversalPending = 'REVERSAL_PENDING',
  reversed = 'REVERSED',
}

export const partialPaymentStatuses = Object.values(PartialPaymentStatus);

export enum BillPaymentType {
  directDebit = 'DIRECT_DEBIT',
  wireTransfer = 'WIRE_TRANSFER',
}

export enum BillPaymentBillPaymentType {
  billPayment = 'BILL_PAYMENT',
  billPaymentReversal = 'BILL_PAYMENT_REVERSAL',
  prefundPayment = 'PREFUND_PAYMENT',
  directDebitReversalFee = 'DIRECT_DEBIT_REVERSAL_FEE',
  payout = 'PAYOUT',
}

export enum NetworkErrorCode {
  hasReceipts = 'HAS_RECEIPTS',
  receiptsNotNeeded = 'RECEIPTS_NOT_NEEDED',
  receiptUploadNotEnabled = 'RECEIPT_UPLOAD_NOT_ENABLED',
  dataIsNotYetReady = 'DATA_IS_NOT_YET_READY',
  orgLimitExceeded = 'ORG_LIMIT_EXCEEDED',
  maxCardLimitExceeded = 'MAX_CARD_LIMIT_EXCEEDED',
  issuanceLimitExceeded = 'ISSUANCE_LIMIT_EXCEEDED',
  bankConnectionImportIncomplete = 'BANK_CONNECTION_IMPORT_INCOMPLETE',
  alreadyExists = 'ALREADY_EXISTS',
  userAlreadyExists = 'USER_ALREADY_EXISTS',
  pmtFrequencyIsLowerThanMinValue = 'PMT_FREQUENCY_IS_LOWER_THAN_MIN_VALUE',
  notFound = 'NOT_FOUND',
  invalidIban = 'INVALID_IBAN',
  ibanIsAlreadyInUse = 'IBAN_IS_ALREADY_IN_USE',
  mandateAlreadyConfirmed = 'MANDATE_ALREADY_CONFIRMED',
  nonUniqueSubcategoryName = 'NON_UNIQUE_SUBCATEGORY_NAME',
  subcategoryHasLink = 'SUBCATEGORY_HAS_LINK',
  invalidVatName = 'INVALID_VAT_NAME',
  invalidVatCode = 'INVALID_VAT_CODE',
  vatRateIsInUse = 'VAT_RATE_IS_IN_USE',
  projectNameIsTaken = 'PROJECT_NAME_IS_TAKEN',
  costUnitIsTaken = 'COST_UNIT_IS_TAKEN',
  costUnitInUse = 'COST_UNIT_IN_USE',
  projectInUse = 'PROJECT_IN_USE',
  projectIsActive = 'PROJECT_IS_ACTIVE',
  projectInCardUseStatus = 'PROJECT_IN_CARD_USE_STATUS',
  accessDenied = 'ACCESS_DENIED',
  transactionDownloadLimit = 'TRANSACTION_DOWNLOAD_LIMIT',
  noTransactionsFound = 'NO_TRANSACTIONS_FOUND',
  noReceiptsFound = 'NO_RECEIPTS_FOUND',
  supplierInUse = 'SUPPLIER_IN_USE', // This supplier is used by at least one transaction and cannot be deleted.
  supplierNameIsTaken = 'SUPPLIER_NAME_IS_TAKEN',
  supplierAccountNumberInUse = 'SUPPLIER_ACCOUNT_NUMBER_IN_USE', // This supplier's account number is assigned to transaction.
  multipleErrors = 'MULTIPLE_ERRORS',
  submissionCooldown = 'SUBMISSION_COOLDOWN',
  notEnoughCertificates = 'NOT_ENOUGH_CERTIFICATES',
  unsupportedCertificateNumberFormat = 'UNSUPPORTED_CERTIFICATE_NUMBER_FORMAT',
  invalidCertificatesRange = 'INVALID_CERTIFICATES_RANGE',
  invalidProjectUrl = 'INVALID_PROJECT_URL',
  payoutImpossible = 'PAYOUT_IMPOSSIBLE',
  varengoldValidationError = 'VARENGOLD_VALIDATION_ERROR',
  invalidLegalForm = 'INVALID_LEGAL_FORM',
  invalidTin = 'INVALID_TIN',
  invalidAccessCode = 'INVALID_ACCESS_CODE',
  amlSanctionScoreMissing = 'AML_SANCTION_SCORE_MISSING',
  datevPermissionRequirementsNotMet = 'DATEV_PERMISSION_REQUIREMENTS_NOT_MET',
  maxUsersThresholdExceeded = 'MAX_USERS_THRESHOLD_EXCEEDED',
  maxVirtualCardsThresholdExceeded = 'MAX_VIRTUAL_CARDS_THRESHOLD_EXCEEDED',
  maxSingleUseCardsThresholdExceeded = 'MAX_SINGLE_USE_CARDS_THRESHOLD_EXCEEDED',
  valueMustBePositive = 'VALUE_MUST_BE_POSITIVE',
  memberLimitExceeded = 'MEMBER_LIMIT_EXCEEDED',
  cardIssuanceLimitExceeded = 'CARD_ISSUANCE_LIMIT_EXCEEDED',
  paymentStatusChangeImpossible = 'PAYMENT_STATUS_CHANGE_IMPOSSIBLE',
  onboardingDocumentFileTooBigError = 'ONBOARDING_DOCUMENT_FILE_TOO_BIG_ERROR',
  onboardingDocumentFileTypeNotAllowedError = 'ONBOARDING_DOCUMENT_FILE_TYPE_NOT_ALLOWED_ERROR',
  hasSequentialNumbers = 'HAS_SEQUENTIAL_NUMBERS',
  hasRepeatingNumbers = 'HAS_REPEATING_NUMBERS',
  incorrectAccountNumber = 'INCORRECT_ACCOUNT_NUMBER',
  incorrectOrganizationState = 'INCORRECT_ORGANIZATION_STATE',
  ibanGenerationError = 'IBAN_GENERATION_ERROR',
  changePhoneRequestAlreadyExists = 'CHANGE_PHONE_REQUEST_ALREADY_EXISTS',
  totalIsLowerThanTakenCardsCount = 'TOTAL_IS_LOWER_THAN_TAKEN_CARDS_COUNT',
  defaultTierFeeShouldBeHighest = 'DEFAULT_TIER_FEE_SHOULD_BE_HIGHEST',
  usedTierCannotBeDeleted = 'USED_TIER_CANNOT_BE_DELETED',
  tierWithSameFeeExists = 'TIER_WITH_SAME_FEE_EXISTS',
  bankSubmissionStatusError = 'BANK_SUBMISSION_STATUS_ERROR',
  illegalArgumentsProvided = 'ILLEGAL_ARGUMENTS_PROVIDED',
  transactionCustomFieldLabelIsTaken = 'TRANSACTION_CUSTOM_FIELD_LABEL_IS_TAKEN',
  transactionCustomFieldIsActive = 'TRANSACTION_CUSTOM_FIELD_IS_ACTIVE',
  transactionCustomFieldInUse = 'TRANSACTION_CUSTOM_FIELD_IN_USE',
  dnbTestDataNotFound = 'DNB_TEST_DATA_NOT_FOUND',
  transactionCustomFieldLimitReached = 'TRANSACTION_CUSTOM_FIELD_LIMIT_REACHED',
  organizationIdAlreadyExists = 'ORGANIZATION_ID_ALREADY_EXISTS',
  invalidSubcategoryNumber = 'INVALID_SUBCATEGORY_NUMBER',
  incorrectLimitError = 'INCORRECT_LIMIT_ERROR',
  crefoIdUpdateError = 'CREFO_ID_UPDATE_ERROR',
}

export enum UnderwritingCase {
  accountCase = 'ACCOUNT_CASE',
  businessCase = 'BUSINESS_CASE',
  creditAgencyCase = 'CREDIT_AGENCY_CASE',
  deepPocketCase = 'DEEP_POCKET_CASE',
  noCreditLimit = 'NO_CREDIT_LIMIT',
}

export const underwritingCases = [
  UnderwritingCase.accountCase,
  UnderwritingCase.businessCase,
  UnderwritingCase.creditAgencyCase,
  UnderwritingCase.deepPocketCase,
  UnderwritingCase.noCreditLimit,
];

export const JWT_CLAIM_NAMESPACE = 'https://infinnity.com';

export const DEFAULT_PAGE_LIMIT = 50;

export const TRESHOLD_LIMIT_FOR_COLLATERAL = 1000000;

export const MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_ZIP_RECEIPTS = 2000;
export const MAX_TRANSACTIONS_NUMBER_TO_DOWNLOAD_CUSTOM_CSV_RECEIPTS = 25000;

export const SUPPORT_NUMBER = '+49 (0)30 544537780';

export const SUPPORT_EMAIL = 'support@getpliant.com';

export const DEFAULT_AUTOCOMPLETE_DELAY = 250;

export const VISA_GCAS_PAGE_URL =
  'https://www.visa.de/support/verbraucher/gestohlene-kreditkarten-melden.html';

export * from './types';

export * from './settlementTransaction';

export * from './countries';

export * from './supportedCountryDetails';

export * from './sessionStorage';

export * from './keys';

export enum DocumentType {
  platformTermsAndConditions = 'PLATFORM_TERMS_AND_CONDITIONS',
  platformTermsAndConditionsPliantOyIssuer = 'PLATFORM_TERMS_AND_CONDITIONS_PLIANT_OY_ISSUER',
  platformTermsAndConditionsEmbeddedWallet = 'PLATFORM_TERMS_AND_CONDITIONS_EMBEDDED_WALLET',
  platformTermsAndConditionsEmbeddedWalletPliantOyIssuer = 'PLATFORM_TERMS_AND_CONDITIONS_EMBEDDED_WALLET_PLIANT_OY_ISSUER',
  platformTermsAndConditionsFullyEmbedded = 'PLATFORM_TERMS_AND_CONDITIONS_FULLY_EMBEDDED',
  platformTermsAndConditionsFullyEmbeddedPliantOyIssuer = 'PLATFORM_TERMS_AND_CONDITIONS_FULLY_EMBEDDED_PLIANT_OY_ISSUER',
  platformTermsAndConditionsPliantOyCoba = 'PLATFORM_TERMS_AND_CONDITIONS_PLIANT_OY_COBA',
  partnerBankTermsAndConditionsVarengold = 'PARTNER_BANK_TERMS_AND_CONDITIONS_VARENGOLD',
  issuerTermsAndConditionsPliantOyVg = 'ISSUER_TERMS_AND_CONDITIONS_PLIANT_OY_VG',
  issuerTermsAndConditionsPliantOyBc = 'ISSUER_TERMS_AND_CONDITIONS_PLIANT_OY_BC',
  issuerTermsAndConditionsTpml = 'ISSUER_TERMS_AND_CONDITIONS_TPML',
  pliantPaymentServicesTermsAndConditionsPliantOyCoba = 'PLIANT_PAYMENT_SERVICES_TERMS_AND_CONDITIONS_PLIANT_OY_COBA', // DocumentGenericType insuranceTerms
  travelInsuranceTerms = 'TRAVEL_INSURANCE_TERMS',
  platformDataPrivacyPolicyWeb = 'PLATFORM_DATA_PRIVACY_POLICY_WEB',
  platformDataPrivacyPolicyMobile = 'PLATFORM_DATA_PRIVACY_POLICY_MOBILE',
  partnerBankDataPrivacyPolicyVarengold = 'PARTNER_BANK_DATA_PRIVACY_POLICY_VARENGOLD',
  issuerDataPrivacyPolicyPliantOy = 'ISSUER_DATA_PRIVACY_POLICY_PLIANT_OY',
  issuerDataPrivacyPolicyTpml = 'ISSUER_DATA_PRIVACY_POLICY_TPML',
}

export const documentTypes = Object.values(DocumentType);

export enum DocumentGenericType {
  bankDataPrivacyPolicy = 'BANK_DATA_PRIVACY_POLICY',
  bankTermsAndConditions = 'BANK_TERMS_AND_CONDITIONS',
  insuranceTerms = 'INSURANCE_TERMS',
  issuerDataPrivacyPolicy = 'ISSUER_DATA_PRIVACY_POLICY',
  issuerTermsAndConditions = 'ISSUER_TERMS_AND_CONDITIONS',
  platformDataPrivacyPolicyMobile = 'PLATFORM_DATA_PRIVACY_POLICY_MOBILE',
  platformDataPrivacyPolicyWeb = 'PLATFORM_DATA_PRIVACY_POLICY_WEB',
  platformTermsAndConditions = 'PLATFORM_TERMS_AND_CONDITIONS',
}

export enum IntegrationType {
  circula = 'CIRCULA',
  candis = 'CANDIS',
  bezala = 'BEZALA',
  klippa = 'KLIPPA',
  none = 'NONE',
  undefined = 'UNDEFINED',
}

export enum AccountingSystem {
  aeroviaggi = 'CUSTOM_AEROVIAGGI',
  datev = 'DATEV',
  customDatevBauen = 'CUSTOM_DATEV_BAUEN',
  microsoftDynamics365BusinessCentral = 'MICROSOFT_DYNAMICS_365_BUSINESS_CENTRAL',
  oracleNetSuite = 'ORACLE_NET_SUITE',
  lexOffice = 'LEX_OFFICE',
  lexware = 'LEXWARE',
  netvisor = 'NETVISOR',
  sevDesk = 'SEV_DESK',
  sapS4hana = 'SAP_S_4HANA',
  sapBusinessOne = 'SAP_BUSINESS_ONE',
  sapBusinessByDesign = 'SAP_BUSINESS_BY_DESIGN',
  inforErp = 'INFOR_ERP',
  xero = 'XERO',
  quickbooks = 'QUICKBOOKS',
  sage50 = 'SAGE_50',
  sage100 = 'SAGE_100',
  sageX3 = 'SAGE_X3',
  xentral = 'XENTRAL',
  odoo = 'ODOO',
  bmd = 'BMD',
  orlando = 'ORLANDO',
  proAlpha = 'PRO_ALPHA',
  procountor = 'PROCOUNTOR',
  other = 'OTHER',
  camt = 'CAMT_053',
  customDemy = 'CUSTOM_DEMY',
  customEdreams = 'CUSTOM_EDREAMS',
  customEasyMarket = 'CUSTOM_EASY_MARKET',
  // custom Locoia accounting systems (custom CSV)
  customBusinessCenral = 'CUSTOM_BUSINESS_CENTRAL',
  customSapdt = 'CUSTOM_SAPDT',
  customSap = 'CUSTOM_SAP',
  customSapIbp = 'CUSTOM_SAPIBP',
  customOdoo = 'CUSTOM_ODOO',
  customTalenom = 'CUSTOM_TALENOM',
  customNetsuite = 'CUSTOM_NETSUITE',
  customAbas = 'CUSTOM_ABAS',
}

export enum AccountingSystemIntegrationMethod {
  api = 'API',
  codat = 'CODAT',
  file = 'FILE',
  locoia = 'LOCOIA',
  none = 'NONE',
}

export enum ExportFormat {
  xml = 'XML',
  csv = 'CSV',
  csvMerged = 'CSV_MERGED',
  csvSmartExcel = 'CSV_SMART_EXCEL',
  api = 'API',
}

// InternalExportFormat is used for correct mapping of formats
// and flows on Export page. Current formats exist only on FE.
export enum InternalExportFormat {
  customCsv = 'CUSTOM_CSV',
}

export const internalExportFormats = Object.values(InternalExportFormat);

export const DatevExportFormatOptions = [
  'CSV',
  'XML',
  'CSV&XML',
  'API',
] as const;
export type DatevExportFormatOption = typeof DatevExportFormatOptions[number];

export enum DatevCostMode {
  standard = 'STANDARD',
  swap = 'SWAP',
}

export enum ApiIntegrationStatus {
  connected = 'CONNECTED',
  notConnected = 'NOT_CONNECTED',
  needsWork = 'NEEDS_WORK',
}

export const CARD_NAME_MAX_LENGTH = 40;
export const CARDHOLDER_FULL_NAME_MAX_LENGTH = 25;
export const CARD_PURPOSE_MAX_LENGTH = 200;
export const EMAIL_MAX_LENGTH = 50;

export const SUBCATEGORY = {
  nameMaxLength: 100,
  numberMaxLength: 12,
};

export const VAT_RATE = {
  nameMaxLength: 50,
  rateMinVal: 0,
  rateMaxVal: 100,
  codeMaxLength: 12,
};

export const CUSTOM_FIELD = {
  labelMaxLength: 30,
  valueMaxLength: 80,
  descriptionMaxLength: 100,
};

export enum MerchantStatus {
  new = 'NEW',
  draft = 'DRAFT',
  published = 'PUBLISHED',
}

export const merchantStatuses = Object.values(MerchantStatus);

export enum MerchantSplitType {
  none = 'NONE',
  mainMerchant = 'MAIN_MERCHANT',
  splitMerchant = 'SPLIT_MERCHANT',
}

export enum MerchantDescriptionSource {
  authorization = 'AUTHORIZATION',
  confirmation = 'CONFIRMATION',
}

export enum ForwardingIntegrationStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  deleted = 'DELETED',
}

export enum ForwardingIntegrationFrequency {
  instant = 'INSTANT',
  daily = 'DAILY',
}

export enum ForwardUnmatchedReceiptsInterval {
  immediately = 'IMMEDIATELY',
}

export enum CirculaConnectionStatus {
  connected = 'CONNECTED',
  disconnected = 'DISCONNECTED',
}

export enum CirculaConnectionType {
  partial = 'PARTIAL',
  full = 'FULL',
}

export enum ApiExportTransactionStatus {
  inProgress = 'IN_PROGRESS',
  done = 'DONE',
  canceled = 'CANCELED',
  failed = 'FAILED',
}

export enum SupplierUploadErrorCode {
  invalidSupplierName = 'INVALID_SUPPLIER_NAME',
  invalidSupplierAccountNumber = 'INVALID_SUPPLIER_ACCOUNT_NUMBER',
  supplierNameIsTaken = 'SUPPLIER_NAME_IS_TAKEN',
}

export enum GlAccountUploadErrorCode {
  invalidSubcategoryCategory = 'INVALID_SUBCATEGORY_CATEGORY',
  invalidSubcategoryName = 'INVALID_SUBCATEGORY_NAME',
  invalidSubcategoryNumber = 'INVALID_SUBCATEGORY_NUMBER',
  nonUniqueSubcategoryName = 'NON_UNIQUE_SUBCATEGORY_NAME',
}

export enum ProjectUploadErrorCode {
  costUnitIsTaken = 'COST_UNIT_IS_TAKEN',
  projectFieldLengthExceedsLimit = 'PROJECT_FIELD_LENGTH_EXCEEDS_LIMIT',
  projectInvalidCostUnit = 'PROJECT_INVALID_COST_UNIT',
  projectNameIsTaken = 'PROJECT_NAME_IS_TAKEN',
}

export enum PartnerStatus {
  inactive = 'INACTIVE',
  active = 'ACTIVE',
}

export enum PartnerAuthStatus {
  inactive = 'INACTIVE',
  pending = 'PENDING',
  active = 'ACTIVE',
}

// information about partner scopes:
// https://infinnity.atlassian.net/wiki/spaces/DEV/pages/663257164/Add+a+new+partner+to+our+Partner+API#Partner-Scopes
export enum PartnerScope {
  onlyIntegrationsPage = 'ONLY_INTEGRATIONS_PAGE',
  sync = 'SYNC',
  embeddedWallet = 'EMBEDDED_WALLET',
  fullyEmbedded = 'FULLY_EMBEDDED',
  pliantInternal = 'PLIANT_INTERNAL',
  customer = 'CUSTOMER',
  whitelabel = 'WHITELABEL',
  logoCard = 'LOGO_CARD',
}

// This should be a temporary solution before we have a proper config from BE.
// The ids are the only constant property on which we can rely for all ENVs.
export const PartnerIds = {
  pliant: 'c61ca45f-aa8d-4254-90fc-6e8adeed0ed8',
  klippa: '7e59ee92-d6b5-42bd-b836-bd4f73018bef',
  conferma: '452651bf-e3fb-45a5-89ed-8e9f03a5a730',
  bezala: 'af43c25d-32a9-46a9-a93c-2046d4e638ce',
  intertours: '059988db-5073-4c9a-843c-335d0b13dcf9',
  erichUtsch: 'ba2ec958-cffc-4a49-84dc-99d613a2c4b5',
  xaltId: '21d6d408-0ec3-4f93-a1ad-43056728535f',
  mobilexpenseDeclaree: '5fd21870-6fa5-43fd-a388-a5080c1184a0',
  mobilexpenseMXP: '924a6b43-f709-471d-92db-ffb4f22b58e5',
  candis: '3a2947e2-ded8-4889-9f88-34f26b643af8',
  circula: '4dc30867-dafe-4d78-a6c0-15e42deb0229',
  circulaDemo: 'f3ec2126-046a-42be-b4ec-b8556e4b946b',
  circulaEmbedded: '2bf36aca-44ee-484a-a6c0-0958bfd106c2',
  invia: '5b83cd83-25f0-4a1b-844a-b929b159dd9b',
  bbva: '0ac52d05-f6bf-43ca-82c3-589c9d4e7f55',
  bizaway: '91f43fd9-bed2-4014-861e-4a0618bea6f7',
  commerzbank: 'bfa9a428-c7c6-4436-b5b6-b83ee6b3f0aa',
  mocoapp: '5c86d18b-49aa-4f0f-b37b-cb17e40b19b6', // hundertzehn - official name
  voxel: 'ea67f041-61da-4dbb-9952-3d76824387bb',
  bas: '3669fb52-1bba-4be8-ab4d-93698e11caa0', // Fadiro
  co2move: 'ad9e7591-224e-433b-a832-d9b65215594e',
  scopevisio: '3a4a437a-aa49-4c1b-b2b1-214f97f8f9c9',
} as const;

export type PartnerId = typeof PartnerIds[keyof typeof PartnerIds];
export type PartnerIdType = PartnerId | Omit<string, PartnerId>;

export enum PartnerName {
  pliant = 'pliant',
  circula = 'Circula',
  candis = 'Candis',
  mobilexpense = 'Mobilexpense',
  mobilexpenseMxp = 'Mobilexpense MXP',
  bezala = 'Bezala',
  intertours = 'Intertours',
  klippa = 'Klippa',
  bbva = 'BBVA & Pliant',
  bizaway = 'BizzyPay',
  commerzbank = 'Pliant x Commerzbank',
  voxel = 'Voxel',
  conferma = 'Conferma',
  bas = 'BAS',
  scopevisio = 'Scopevisio AG',
}

export enum CardVelocityGroupLimits {
  physicalBasic = 'PLI-VL-001',
  virtualBasic = 'PLI-VL-002',
  virtualHigh = 'PLI-VL-003',
  physicalHigh = 'PLI-VL-004',
}

export const physicalCardVelocityGroupLimit = [
  {
    key: 'basic',
    value: CardVelocityGroupLimits.physicalBasic,
  },
  {
    key: 'high',
    value: CardVelocityGroupLimits.physicalHigh,
  },
];

export const virtualCardVelocityGroupLimit = [
  {
    key: 'basic',
    value: CardVelocityGroupLimits.virtualBasic,
  },
  {
    key: 'high',
    value: CardVelocityGroupLimits.virtualHigh,
  },
];

export enum ReceiptRejectionReason {
  poorQuality = 'POOR_QUALITY',
  incorrectReceipt = 'INCORRECT_RECEIPT',
  missingInformation = 'MISSING_INFORMATION',
  other = 'OTHER',
}

export const receiptRejectionReasons = Object.values(ReceiptRejectionReason);

export enum SupportedWebAppLanguage {
  de = 'de',
  en = 'en',
  es = 'es',
  fi = 'fi',
  fr = 'fr',
  it = 'it',
  nl = 'nl',
  pt = 'pt',
}

export const IntegrationName = {
  bezala: PartnerName.bezala,
  circula: PartnerName.circula,
  datev: 'DATEV',
  tidely: 'TIDELY',
  candis: PartnerName.candis,
  kanzleiland: 'KANZLEITLAND',
  rydoo: 'RYDOO',
  buchhaltungsButler: 'BUCHHALTUNGS_BUTLER',
  getMyInvoices: 'GET_MY_INVOICES',
  flowwer: 'FLOWWER',
  bookman: 'BOOKMAN',
  hellohq: 'HELLO_HQ',
  mobilexpense: PartnerName.mobilexpense,
  mobilexpenseMXP: PartnerName.mobilexpenseMxp,
  domonda: 'DOMONDA',
  commitly: 'COMMITLY',
  freeFinance: 'FREE_FINANCE',
  agicap: 'AGICAP',
  mocoapp: 'MOCOAPP',
  lexOffice: 'LEX_OFFICE',
  customCSV: 'CUSTOM_CSV',
  netvisor: 'NETVISOR',
  procountor: 'PROCOUNTOR',
  xero: 'XERO',
  quickbooks: 'QUICKBOOKS',
  oracleNetSuite: 'ORACLE_NET_SUITE',
  microsoftDynamics365BusinessCentral:
    'MICROSOFT_DYNAMICS_365_BUSINESS_CENTRAL',
  klippa: PartnerName.klippa,
  voxel: PartnerName.voxel,
  conferma: PartnerName.conferma,
  bas: PartnerName.bas,
  scopevisio: PartnerName.scopevisio,
} as const;

export type IntegrationNameType = typeof IntegrationName[keyof typeof IntegrationName];

export enum IntegrationSection {
  financeIntegrations = 'financeIntegrations',
  accountingIntegrations = 'accountingIntegrations',
}

export enum ReceiptRejectionReasons {
  poorQuality = 'POOR_QUALITY',
  incorrectReceipt = 'INCORRECT_RECEIPT',
  missingInformation = 'MISSING_INFORMATION',
  other = 'OTHER',
}

export enum BankSubmissionStatus {
  draft = 'DRAFT',
  pendingApproval = 'PENDING_APPROVAL',
  submitted = 'SUBMITTED',
  validated = 'VALIDATED',
}

export enum AmlRiskScoring {
  high = 'HIGH',
  medium = 'MEDIUM',
  low = 'LOW',
}

/** @deprecated - use ComplianceStaticData from BE instead */
export const amlRiskScorings = [
  AmlRiskScoring.low,
  AmlRiskScoring.medium,
  AmlRiskScoring.high,
];

export enum ComplianceRatingValue {
  high = 'HIGH',
  low = 'LOW',
  medium = 'MEDIUM',
}

export enum RelatedIndividualType {
  shareholder = 'SHAREHOLDER',
  management = 'MANAGEMENT',
}

export enum UboType {
  fictive = 'FICTIVE',
  notUbo = 'NOT_UBO',
  other = 'OTHER',
  shareholding = 'SHAREHOLDING',
}

export const uboTypes = [
  UboType.shareholding,
  UboType.other,
  UboType.fictive,
  UboType.notUbo,
];

export enum LegitimationDocument {
  P = 'P',
  R = 'R',
}

export enum LegitimationProvider {
  I = 'I',
  R = 'R',
  E = 'E',
  P = 'P',
  W = 'W',
  S = 'S',
}

export enum OrganizationRepresentativeExternalStatus {
  infoRequested = 'INFO_REQUESTED',
  infoFilled = 'INFO_FILLED',
  infoSubmitted = 'INFO_SUBMITTED',
  internalOnly = 'INTERNAL_ONLY',
}

export enum OrganizationRepresentativeInternalStatus {
  archived = 'ARCHIVED',
  draft = 'DRAFT',
  ready = 'READY',
}

// This enum is outdated - we have many more partner plan types,
// and the new ones are constantly added
export enum SubscriptionPlanType {
  starter = 'STARTER', // deprecated
  premium = 'PREMIUM', // deprecated
  enterprise = 'ENTERPRISE', // deprecated
  partnerCandis = 'PARTNER_CANDIS',
  partnerCircula = 'PARTNER_CIRCULA',
  partnerConferma = 'PARTNER_CONFERMA',
  partnerMobilexpense = 'PARTNER_MOBILEXPENSE',
  light = 'LIGHT',
  standard = 'STANDARD',
  custom = 'CUSTOM',
}

export const organizationPlans = Object.values(SubscriptionPlanType);

export enum SubscriptionPlanParam {
  BASE_FEE = 'baseFee',
  ADDITIONAL_USER_FEE = 'additionalUserFee',
  MAX_ACTIVE_MEMBERS_COUNT = 'maxActiveMembersCount',
  MAX_VIRTUAL_CARDS_COUNT = 'maxVirtualCardsCount',
  FREE_MEMBERS_COUNT = 'freeMembersCount',
  MAX_SINGLE_USE_CARDS_COUNT = 'maxSingleUseCardsCount',
}

export const subscriptionPlanParams = Object.values(SubscriptionPlanParam);

export enum ReceiptNumberExportMode {
  useOrAutogenerateIfEmpty = 'USE_OR_AUTOGENERATE_IF_EMPTY',
  use = 'USE',
  dontUse = 'DONT_USE',
}

export enum ReceiptDateExportMode {
  bookingDate = 'BOOKING_DATE',
  receiptDate = 'RECEIPT_DATE',
  dynamic = 'DYNAMIC',
}

export const receiptDateExportModes = Object.values(ReceiptDateExportMode);

export enum ReceiptAutomatchStatus {
  NONE = 'NONE',
  MANUAL = 'MANUAL',
  AUTOMATCHED_SUCCESS = 'AUTOMATCHED_SUCCESS',
  AUTOMATCHED_FAILURE = 'AUTOMATCHED_FAILURE',
}

export enum BookingTextMode {
  SUPPLIER_OR_MERCHANT_AND_COMMENT = 'SUPPLIER_OR_MERCHANT_AND_COMMENT',
  SUPPLIER_OR_MERCHANT = 'SUPPLIER_OR_MERCHANT',
  COMMENT = 'COMMENT',
  SUPPLIER_OR_MERCHANT_AND_MERCHANT_NAME_AND_COMMENT = 'SUPPLIER_OR_MERCHANT_AND_MERCHANT_NAME_AND_COMMENT',
}

export enum LegitimationStatus {
  COMPLETED = 'COMPLETED',
  FRAUD = 'FRAUD',
  NOT_REQUESTED = 'NOT_REQUESTED',
  ONGOING = 'ONGOING',
}

export enum OnboardingDocumentName {
  ID_COPY = 'ID_COPY',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  // other OnboardingDocumentName values are dynamically generated from the backend
}

export enum OnboardingDocumentCategory {
  COMPLIANCE = 'COMPLIANCE',
  RISK = 'RISK',
  UBO = 'UBO',
}

export enum OnboardingDocumentAllowedAttribute {
  amount = 'amount',
  date = 'date',
  fromMonthYear = 'fromMonthYear',
  ibanRef = 'ibanRef',
  orgName = 'orgName',
  toMonthYear = 'toMonthYear',
  year = 'year',
}

export enum OnboardingDocumentStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  SUBMITTED = 'SUBMITTED',
}

export enum CompanyRegistryProvider {
  crefo = 'CREFO',
  dnb = 'DNB',
}

export enum CodatDataItemStatus {
  new = 'NEW',
  selected = 'SELECTED',
  unselected = 'UNSELECTED',
}

export enum CodatMappingTarget {
  costCenter = 'COST_CENTER',
  costUnit = 'COST_UNIT',
}

export enum ComplianceDocumentUploadOption {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  CHANGE_WITH_OBJECTION = 'CHANGE_WITH_OBJECTION',
  ACTIVE_OPT_IN = 'ACTIVE_OPT_IN',
}

export enum SmsLogSource {
  AWS = 'AWS',
  auth0 = 'AUTH0',
}

export enum SmsLogStatus {
  FALIURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export const DEFAULT_CURRENCY = EUR;

export const DEFAULT_TIMEZONE = 'Europe/Amsterdam';

export const DEFAULT_TIMEZONE_OFFSET = 'UTC+02:00';

export enum EnterpriseSize {
  L = 'L',
  M = 'M',
  S = 'S',
  XS = 'XS',
}

export enum ServiceLevel {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum XlsxUploadFailureStatus {
  uploadInProgress = 'UPLOAD_IN_PROGRESS',
  orgNotActive = 'ORG_NOT_ACTIVE',
  teamsFeatureDisabled = 'TEAMS_FEATURE_DISABLED',
  noDataFound = 'NO_DATA_FOUND',
}

export enum XlsxUploadSuccessStatus {
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED',
}

export enum XlsxUploadType {
  members = 'MEMBERS',
  teams = 'TEAMS',
  cards = 'CARDS',
}

export enum PartnerWhitelabelLevel {
  PLIANT_DEFAULT = 'PLIANT_DEFAULT',
  COMPLIANCE_RISK_WHITELABEL = 'COMPLIANCE_RISK_WHITELABEL',
  FULLY_WHITELABEL = 'FULLY_WHITELABEL',
  EMBEDDED_WHITELABEL = 'EMBEDDED_WHITELABEL', // IN-11855
  LOGO_ONLY = 'LOGO_ONLY', // IN-14561
}

export interface GetCardAccountsSettingsParams {
  organizationId: string;
  page: number;
  limit: number;
  q?: string;
  sort?: string;
  status?: CardAccountStatus;
}

export interface CardAccountSettings {
  cardAccountId: string;
  cardAccountName: string;
  pliantCreditCardAccount: string;
  defaultAccount: boolean;
}

export interface CardAccountsSettingsPageableList {
  cardAccountSettings: CardAccountSettings[];
  hasNextPage: boolean;
  totalCount: number;
}

export interface UpdateCardAccountSettingsPayload {
  organizationId: string;
  pliantCreditCardAccount: string;
}

export enum IdentificationType {
  IDNOW_PLIANT_OY_AUTOIDENT = 'IDNOW_PLIANT_OY_AUTOIDENT',
  IDNOW_PLIANT_OY_VIDEOIDENT = 'IDNOW_PLIANT_OY_VIDEOIDENT',
  IDNOW_VG_VIDEOIDENT = 'IDNOW_VG_VIDEOIDENT',
}

export const IntegrationColor = {
  [IntegrationType.circula]: '#002d40',
  [IntegrationType.bezala]: '#167576',
  [IntegrationType.candis]: '#2c192e',
  [IntegrationType.klippa]: '#01c464',
};

export enum MimeType {
  pdf = 'application/pdf',
  jpeg = 'image/jpeg',
  png = 'image/png',
  tiff = 'image/tiff',
}

export enum CustomFieldStatus {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

export enum CustomFieldVisibility {
  adminAndCardholder = 'ADMIN_AND_CARDHOLDER',
  adminOnly = 'ADMIN_ONLY',
  nowhere = 'NOWHERE',
}

export const customFieldsVisibilityOptions = Object.values(
  CustomFieldVisibility
);

export enum AutomationType {
  card = 'CARD',
  merchant = 'MERCHANT', // not used on BE yet
}

export enum CustomFieldType {
  text = 'TEXT',
}

export enum ExternalReportProvider {
  crefo = 'CREFO',
  dnb = 'DNB',
}

export enum ExpenseType {
  business = 'BUSINESS',
  private = 'PRIVATE',
}

export const expenseTypes = Object.values(ExpenseType);

export enum ExpenseTypeChangeableBy {
  noOne = 'NO_ONE',
  nonCardholder = 'NON_CARDHOLDER',
  all = 'ALL',
}

export enum ReceiptsAutoMatchingTagGroup {
  none = 'NONE',
  unmatched = 'UNMATCHED',
  matched = 'MATCHED',
  trash = 'TRASH',
}

export enum ReceiptsAutoMatchingStatus {
  noTransactionFound = 'NO_TRANSACTION_FOUND',
  notReadable = 'NOT_READABLE',
  processing = 'PROCESSING',
  externalLink = 'EXTERNAL_LINK',
  matchedAuto = 'MATCHED_AUTO',
  matchedManually = 'MATCHED_MANUALLY',
  deletedByCustomer = 'DELETED_BY_CUSTOMER',
  notPaidWithPliant = 'NOT_PAID_WITH_PLIANT',
  duplicate = 'DUPLICATE',
  alreadyExported = 'ALREADY_EXPORTED',
  invalidAttachment = 'INVALID_ATTACHMENT',
  notAReceipt = 'NOT_A_RECEIPT',
}

// FE only enum for Receipt Inbox flows
export enum ReceiptInboxMatchingFlow {
  // the flow starts from tx view
  selectedTxMathing = 'SELECTED_TX_MATCHING',
  // the flow starts from receipt inbox
  selectedReceiptMatching = 'SELECTED_RECEIPT_MATCHING',
}

export enum ReceiptInboxLayout {
  GRID = 'GRID',
  LIST = 'LIST',
}

export enum MobileAppPlatform {
  ios = 'IOS',
  android = 'ANDROID',
}

export enum BankingServicePartner {
  bankingCircle = 'BANKING_CIRCLE',
  varengold = 'VARENGOLD',
}

export enum YesNo {
  YES = 'yes',
  NO = 'no',
}
