import React, { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';
import withFeatureModules from 'components/App/withFeatureModules';
import { LoaderWithOverlay } from 'components/Loader';
import { useGlobalState } from 'context/GlobalState';
import { DEFAULT_PAGE_LIMIT } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';

const OrganizationData = ({ children }: { children: React.ReactNode }) => {
  const ldClient = useLDClient();
  const canUser = useCanUser();
  const { receiptInboxEnabled } = useFlags();
  const { orgId } = useParams<{ orgId: string }>();
  const {
    dispatch,
    state: { organization, featureModules },
  } = useGlobalState();
  const api = useImperativeApi();

  const getAccountingData = async () => {
    const accountingSettings = await api.getAccountingSettings(orgId);
    const [
      subcategories,
      vatRates,
      projects,
      { transactionCustomFields },
    ] = await Promise.all([
      accountingSettings.subcategoryEnabled
        ? api
            .getSubcategories(orgId)
            .then((data) => data.subcategories)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      accountingSettings.vatRateEnabled
        ? api
            .getVatRates(orgId)
            .then((data) => data.vatRates)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      accountingSettings.projectEnabled
        ? api
            .getProjects({ organizationId: orgId })
            .then((data) => data.projects)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      featureModules.TRANSACTION_CUSTOM_FIELDS && canUser('custom-fields:view')
        ? api
            .getCustomFields({
              organizationId: orgId,
              page: 0,
              limit: DEFAULT_PAGE_LIMIT,
            })
            .catch((error) => {
              logError(error);
              return { transactionCustomFields: [] };
            })
        : { transactionCustomFields: [] },
    ]);
    return {
      accountingSettings,
      subcategories,
      vatRates,
      projects,
      transactionCustomFields,
    };
  };

  useEffect(() => {
    (async () => {
      try {
        const [
          organization,
          { cardAccounts },
          organizationIntegrations,
          { teams },
          {
            accountingSettings,
            subcategories,
            vatRates,
            projects,
            transactionCustomFields,
          },
          subscriptionPlan,
          { documents },
          unmatchedReceiptsCounts,
        ] = await Promise.all([
          api.getOrganization(orgId),
          api.getCardAccounts(orgId),
          api.getOrganizationIntegrations(orgId).catch((error) => {
            logError(error);
            return null;
          }),

          api.getTeams({ organizationId: orgId }),
          getAccountingData(),
          api.getSubscriptionPlanParams(orgId).catch((error) => {
            logError(error);
            return null;
          }),
          api.getPublicDocuments(orgId).catch((error) => {
            logError(error);
            return { documents: null };
          }),
          receiptInboxEnabled && canUser('receipt-inbox:view')
            ? api.getReceiptAutoMatchingCount(orgId).catch((error) => {
                logError(error);
                return { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 };
              })
            : { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 },
        ]);

        const [
          { cardConfigs: cardConfigSettings },
          partnerOrgAuthDetails,
        ] = await Promise.all([
          api.getCardConfigSettings(orgId).catch((error) => {
            logError(error);
            return { cardConfigs: [] };
          }),
          canUser('partners:view')
            ? await api.getPartnerOrgAuthDetails(organization.partnerId, orgId)
            : undefined,
        ]);

        await ldClient?.identify({
          kind: 'multi',
          app: { key: 'internal' },
          user: {
            key: organization.id,
            name: organization.name,
            country: organization.billingAddress.country,
            partner: organization.partnerName,
            activatedAt: organization.activatedAt,
          },
        });

        dispatch({
          type: 'SET_SUBSCRIPTION_PLAN_DATA',
          payload: { subscriptionPlan },
        });
        dispatch({ type: 'SET_DOCUMENTS', payload: { documents } });
        dispatch({
          type: 'SET_UNMATCHED_RECEIPTS_COUNT',
          payload: unmatchedReceiptsCounts,
        });
        dispatch({
          type: 'SET_CARD_CONFIG_SETTINGS',
          payload: { cardConfigSettings },
        });
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            organization,
            organizationIntegrations,
            teams,
            teamCount: teams.length,
            accountingSettings,
            subcategories,
            vatRates,
            projects,
            transactionCustomFields,
            partnerOrgAuthDetails,
            cardAccounts,
            defaultCardAccount: cardAccounts.find(
              (item) => item.defaultAccount.value
            )!,
          },
        });
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        logError(error);
      }
    })();

    return () => {
      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: { subscriptionPlan: null },
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: null,
          organizationIntegrations: null,
          teams: [],
        },
      });
    };
  }, []);

  if (!organization) return <LoaderWithOverlay size={32} thickness={3} />;

  return <>{children}</>;
};

export default withFeatureModules(OrganizationData);
