import { greaterThanOrEqual } from 'dinero.js';
import { isCustomLocoiaAccountingSystem } from 'domains/settings/utils';
import {
  AccountingSettings,
  AccountingSystem,
  AccountingSystemIntegrationMethod,
  BankAccount,
  BankAccountTransfersAllowedStatus,
  BankConnection,
  Card,
  CardAccount,
  CardConfigSetting,
  CardSimpleStatus,
  CardStatus,
  CardType,
  CurrencyCashback,
  IntegrationType,
  LegalRepresentativeType,
  Member,
  MemberDetails,
  MemberStatus,
  OpenActiveOptIn,
  Organization,
  OrganizationAccountType,
  organizationOnboardingStatuses,
  OrganizationStatus,
  PartnerConfig,
  PartnerIds,
  PartnerWhitelabelLevel,
  Project,
  Receipt,
  ReceiptStatus,
  ServiceLevel,
  SubscriptionPlan,
  SubscriptionPlanType,
  SupportedCountry,
  Team,
  TeamStatus,
} from 'services/constants';
import { dineroFromMoney, isCardVoid } from 'services/utils';
import {
  ADMIN_ROLES,
  CARDHOLDER_ROLES,
  EXTERNAL_ADMIN_ROLES,
  INTERNAL_ADMIN_ROLES,
  Role,
} from './constants';
import { JwtPayload, JwtPermission } from './types';
import { getMemberRole } from './utils';

const hasSome = (targetArr: string[], valuesArr: string[]) =>
  valuesArr.some((item) => targetArr.includes(item));

const hasAll = (targetArr: string[], valuesArr: string[]) =>
  valuesArr.every((item) => targetArr.includes(item));

const byRole = (
  roles: string[],
  permissionByRole: { [role: string]: boolean }
) => roles.some((role) => permissionByRole[role]);

export const checkPermissionWithEntity = (
  jwtPermissions: string[],
  permission: JwtPermission,
  entity: string
) => {
  const regex = entity
    ? new RegExp(`^${permission}\\((.*,)?(\\*|${entity})(,.*)?\\)$`, 'i')
    : new RegExp(`^${permission}($|\\()`, 'i');

  return jwtPermissions.some((permission) => regex.test(permission));
};

const getPermissions = (
  { attr, roles, permissions: jwtPermissions }: JwtPayload,
  currentMember: MemberDetails,
  organization: Organization | null,
  defaultCardAccount: CardAccount | null,
  subscriptionPlan: SubscriptionPlan | null,
  partnerConfig: PartnerConfig | null
) => {
  const isOrgInOnboarding =
    !!organization &&
    organizationOnboardingStatuses.includes(organization.status);
  const isOrgActive =
    !!organization && OrganizationStatus.active === organization.status;
  const isPliantOrPliantDefault =
    partnerConfig?.partnerId === PartnerIds.pliant ||
    partnerConfig?.whitelabelLevel === PartnerWhitelabelLevel.PLIANT_DEFAULT;
  const isFullyWhitelabelPartner =
    partnerConfig?.whitelabelLevel ===
      PartnerWhitelabelLevel.FULLY_WHITELABEL &&
    partnerConfig?.partnerId !== PartnerIds.pliant;

  return {
    'ch-profile-page:visit': hasSome(roles, CARDHOLDER_ROLES),
    'ch-wallet-page:visit':
      jwtPermissions.includes('GetCards') && hasSome(roles, CARDHOLDER_ROLES),
    'ch-transactions-page:visit':
      jwtPermissions.includes('GetTransactions') &&
      hasSome(roles, CARDHOLDER_ROLES),
    'ch-team-transactions-page:visit':
      jwtPermissions.includes('GetTransactions') &&
      roles.includes(Role.teamManager),
    'account-entries-page:visit':
      jwtPermissions.includes('GetAccountEntries') &&
      hasSome(roles, ADMIN_ROLES),
    'account-entries-zip:download': hasAll(jwtPermissions, [
      'GetAccountEntries',
      'DownloadTransactionReceipts',
    ]),
    'billing-stmt-on-acc-entries-page:download': hasSome(
      roles,
      INTERNAL_ADMIN_ROLES
    ),
    'ch-manager-teams-page:visit':
      jwtPermissions.includes('GetTeams') &&
      hasAll(roles, [Role.cardholder, Role.teamManager]),
    'ch-card-benefits-page:visit':
      jwtPermissions.includes('GetCards') && hasSome(roles, CARDHOLDER_ROLES),

    'profile-page:visit': hasSome(roles, EXTERNAL_ADMIN_ROLES),
    'profile-page-issuance-limits:view': hasSome(roles, [Role.orgAdmin]),
    'profile-page-phone-number:change': jwtPermissions.includes(
      'ChangeMemberPhone'
    ),
    'profile-page-notification-settings:manage': jwtPermissions.includes(
      'UpdateNotificationCategories'
    ),
    'onboarding-role-page:visit':
      checkPermissionWithEntity(jwtPermissions, 'UpdateLegalRep', '') &&
      hasSome(roles, [Role.accountOwner]),
    'onboarding-page:visit':
      jwtPermissions.includes('GetOnboardingDetails') &&
      hasSome(roles, ADMIN_ROLES),
    'onboarding-step-sdd:edit': hasSome(roles, [
      Role.accountOwner,
      Role.orgAdmin,
    ]),
    'onboarding:revert': jwtPermissions.includes('RevertOnboarding'),
    'onboarding-step-sdd:edit-iban': hasAll(jwtPermissions, [
      'AddBankAccount',
      'GetOnboardingDetails',
    ]),
    // todo: rethink this permission
    'onboarding-step-sdd:pass': hasAll(jwtPermissions, [
      'LookupBankInfo',
      'GetBankAccounts',
      'AddBankAccount',
      'ConfirmDirectDebit',
    ]),
    'onboarding-step-tac:edit': hasSome(roles, [
      Role.accountOwner,
      Role.orgAdmin,
    ]),
    'onboarding-step-tac:pass': jwtPermissions.includes(
      'AcceptTermsAndConditions'
    ),
    'onboarding-step-tac-success-toast:view': hasSome(roles, [
      Role.accountOwner,
      Role.orgAdmin,
    ]),
    'onboarding-step-prefund-wait-for-risk-review-completed-toast:view': hasSome(
      roles,
      [Role.accountOwner, Role.orgAdmin]
    ),
    'dashboard-page:visit':
      hasAll(jwtPermissions, [
        'GetOrganizationBalance',
        'GetOrganizationCashback',
        'GetMembersCount',
        'GetCardsCount',
        'GetTopCardSpenders',
        'GetTransactions',
      ]) && hasSome(roles, ADMIN_ROLES),
    'members-page:visit':
      jwtPermissions.includes('GetMembers') && hasSome(roles, ADMIN_ROLES),
    'teams-page:visit':
      jwtPermissions.includes('GetTeams') &&
      hasSome(roles, ADMIN_ROLES) &&
      !isOrgInOnboarding,
    'card-accounts-page:visit': hasSome(roles, ADMIN_ROLES),
    'card-accounts:create': jwtPermissions.includes('CreateCardAccount'),
    'card-account-account-group:change': jwtPermissions.includes(
      'UpdateCardAccountGroup'
    ),
    'cards-page:visit':
      jwtPermissions.includes('GetCards') && hasSome(roles, ADMIN_ROLES),
    'transactions-page:visit':
      hasAll(jwtPermissions, [
        'GetTransactions',
        'GetAccountingSettings',
        'ReadAccountingTransactions',
      ]) && hasSome(roles, ADMIN_ROLES),
    'export-page:visit':
      hasAll(jwtPermissions, [
        'GetTransactions',
        'GetAccountingSettings',
        'ReadAccountingTransactions',
      ]) && hasSome(roles, ADMIN_ROLES),
    'statements-page:visit':
      hasAll(jwtPermissions, [
        'GetBills',
        'GetPaymentFrequency',
        'GetAccountingSettings',
        'GetProcessingAccountInfo',
      ]) && hasSome(roles, ADMIN_ROLES),
    'billing-payments-page:visit': jwtPermissions.includes('GetBills'),
    'billing-statements-page:visit': jwtPermissions.includes('GetBills'),
    'rewards-page:visit':
      jwtPermissions.includes('GetOrganizationCashback') &&
      ((hasSome(roles, EXTERNAL_ADMIN_ROLES) &&
        (isPliantOrPliantDefault || isFullyWhitelabelPartner)) ||
        hasSome(roles, INTERNAL_ADMIN_ROLES)),
    // TODO: duplicated permission
    'cashbacks-subpage:visit':
      jwtPermissions.includes('GetOrganizationCashback') &&
      hasSome(roles, ADMIN_ROLES),
    'card-benefits-subpage:visit': hasSome(roles, EXTERNAL_ADMIN_ROLES),
    'settings-page:visit': hasSome(roles, ADMIN_ROLES),
    'integrations-settings-page:visit':
      (hasSome(roles, EXTERNAL_ADMIN_ROLES) &&
        (isPliantOrPliantDefault || isFullyWhitelabelPartner)) ||
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'bank-accounts-subpage:visit':
      hasAll(jwtPermissions, ['GetBankAccounts', 'GetBankConnections']) &&
      hasSome(roles, ADMIN_ROLES),
    'feature-modules-subtab:visit': hasSome(roles, ADMIN_ROLES),
    'integrations-subpage:visit':
      jwtPermissions.includes('GetIntegrationSettings') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'accounting-sub-page:visit': jwtPermissions.includes(
      'GetAccountingSettings'
    ),
    'categories-sub-page:visit':
      jwtPermissions.includes('GetSubcategories') &&
      hasSome(roles, [
        ...INTERNAL_ADMIN_ROLES,
        Role.accountant,
        Role.accountingSettingsManager,
        Role.orgAdmin,
        Role.accountOwner,
        Role.auditor,
      ]),
    'vat-rates-sub-page:visit':
      jwtPermissions.includes('GetVatRates') && hasSome(roles, ADMIN_ROLES),
    'card-accounts-sub-page:visit':
      jwtPermissions.includes('GetAccountingSettings') &&
      hasSome(roles, ADMIN_ROLES),
    'projects-sub-page:visit': jwtPermissions.includes('GetProjects'),
    'suppliers-sub-page:visit': jwtPermissions.includes('GetSuppliers'),
    'pliant-earth-page:visit': hasSome(roles, ADMIN_ROLES),

    'organizations-page:visit':
      jwtPermissions.includes('GetOrganizations') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),

    'general-terms-page:visit':
      jwtPermissions.includes('GetPaymentDeferment') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'subscription-settings-page:visit': hasSome(roles, INTERNAL_ADMIN_ROLES),
    'cashback-settings-page:visit':
      jwtPermissions.includes('GetOrganizationCashback') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),

    'insurance-conditions-document:view': !isFullyWhitelabelPartner,
    'credit-and-compliance-page:visit':
      hasAll(jwtPermissions, [
        'GetRiskAndComplianceDetails',
        'GetPaymentFrequency',
        'GetProbabilityOfDefault',
      ]) && hasSome(roles, INTERNAL_ADMIN_ROLES),
    'settlements-page:visit':
      jwtPermissions.includes('GetSettlements') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'processing-account-page:visit':
      hasAll(jwtPermissions, [
        'GetCardProcessingAccountBalance',
        'GetAccountTransactions',
      ]) && hasSome(roles, INTERNAL_ADMIN_ROLES),
    'processing-account:change': jwtPermissions.includes(
      'AssignCardProcessingAccount'
    ),
    'global-transactions-page:visit':
      jwtPermissions.includes('GetTransactions') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'users-page:visit':
      jwtPermissions.includes('GetMembers') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'crm-integration-page:visit':
      jwtPermissions.includes('GetHubspotData') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'merchants-page:visit':
      jwtPermissions.includes('GetMerchants') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'organization-merchants-page:visit':
      jwtPermissions.includes('GetMerchants') && hasSome(roles, ADMIN_ROLES),
    'program-settlements-page:visit':
      jwtPermissions.includes('GetSettlements') &&
      hasSome(roles, [
        Role.platformAdmin,
        Role.platformReadOnly,
        Role.paymentOps,
      ]),
    'settlement-account-page:visit':
      jwtPermissions.includes('GetAccountTransactions') &&
      jwtPermissions.includes('GetSettlementGroups') &&
      hasSome(roles, [
        Role.platformAdmin,
        Role.platformReadOnly,
        Role.paymentOps,
      ]),
    'receivable-repayments-page:visit': jwtPermissions.includes('GetPayments'),
    'bill-payment-batches-page:visit':
      jwtPermissions.includes('GetBillPayments') &&
      hasSome(roles, [
        Role.platformAdmin,
        Role.platformReadOnly,
        Role.paymentOps,
      ]),
    'cashback-payment-batches-page:visit':
      jwtPermissions.includes('GetRedemptionPaymentBatches') &&
      hasSome(roles, [
        Role.platformAdmin,
        Role.platformReadOnly,
        Role.paymentOps,
      ]),
    'compensation-batches-page:visit':
      jwtPermissions.includes('GetRedemptionPaymentBatches') &&
      hasSome(roles, [Role.platformAdmin, Role.platformReadOnly]),
    'payout-batches-page:visit':
      jwtPermissions.includes('GetBillPayments') &&
      hasSome(roles, [
        Role.platformAdmin,
        Role.platformReadOnly,
        Role.paymentOps,
      ]),
    'billing-accounts-approval-page:visit':
      jwtPermissions.includes('GetBankAccounts') &&
      hasSome(roles, [
        Role.platformAdmin,
        Role.platformReadOnly,
        Role.paymentOps,
      ]),
    'top-up-approval-status:change': jwtPermissions.includes(
      'UpdateBankAccount'
    ),
    'top-up-payment-status:change': jwtPermissions.includes(
      'ManageTopupPayments'
    ),

    // todo: combine into one permission similar to BE?
    'receipt-not-needed:change': jwtPermissions.includes('ManageReceipts'),
    'receipt:upload': jwtPermissions.includes('ManageReceipts'),
    'receipt:update': jwtPermissions.includes('ManageReceipts'),
    'receipt:delete': jwtPermissions.includes('ManageReceipts'),
    'receipt:reject': (receipt: Receipt) =>
      jwtPermissions.includes('RejectReceipts') &&
      currentMember.id !== receipt.memberId &&
      receipt.status !== ReceiptStatus.rejected,
    'receipt-rejected-by-acc-system:update': hasAll(jwtPermissions, [
      'ManageCodatData',
      'ManageReceipts',
    ]),
    'receipt-not-needed-restriction:bypass':
      hasSome(roles, INTERNAL_ADMIN_ROLES) ||
      hasSome(roles, EXTERNAL_ADMIN_ROLES),

    'accounting-transaction:update': jwtPermissions.includes(
      'WriteAccountingTransactions'
    ),

    'app:switch':
      hasSome(roles, CARDHOLDER_ROLES) &&
      hasSome(roles, EXTERNAL_ADMIN_ROLES) &&
      !isOrgInOnboarding,

    'feature-module:toggle': jwtPermissions.includes('ToggleFeatureModule'),
    'internal-feature-module:toggle': jwtPermissions.includes(
      'ToggleInternalFeatureModule'
    ),

    'org-onboarding-questions:internal-view': hasSome(
      roles,
      INTERNAL_ADMIN_ROLES
    ),
    'org-onboarding-questions:submit': jwtPermissions.includes(
      'SubmitOnboardingDocuments'
    ),
    'org-onboarding-document-files:manage':
      jwtPermissions.includes('ManageOnboardingDocumentFilesExternal') ||
      jwtPermissions.includes('ManageOnboardingDocumentFilesInternal'),
    'legal-rep-identification-results:download': jwtPermissions.includes(
      'GetRepresentativesIdentificationArchive'
    ),
    'legal-rep-additional-info:update': jwtPermissions.includes(
      'UpdateRepresentativesAdditionalInfo'
    ),

    'non-customer-org:create': jwtPermissions.includes(
      'CreateNonCustomerOrganization'
    ),
    'org:create': jwtPermissions.includes('CreateOrganization'),
    'org:activate': jwtPermissions.includes('ActivateOrganization'),
    'org:deactivate': jwtPermissions.includes('DeactivateOrganization'),
    'org:cancel':
      jwtPermissions.includes('CancelOrganization') && isOrgInOnboarding,
    'org:resume-onboarding':
      jwtPermissions.includes('ResumeOnboarding') &&
      !!organization &&
      organization.status === OrganizationStatus.cancelled,
    'org-account-type:change': jwtPermissions.includes(
      'ChangeOrganizationAccountType'
    ),
    'org-legal-name:change': jwtPermissions.includes('ChangeLegalName'),
    'org-trade-name:change': jwtPermissions.includes('ChangeTradeName'),
    'org-billing-email-address:change':
      jwtPermissions.includes('ChangeBillingEmail') &&
      (!!organization?.billingEmail ||
        defaultCardAccount?.accountType.value ===
          OrganizationAccountType.prefunded),
    'org-billing-address:change': jwtPermissions.includes(
      'ChangeBillingAddress'
    ),
    'org-delivery-address:change': jwtPermissions.includes(
      'ChangeDeliveryAddress'
    ),
    'org-registration:change': jwtPermissions.includes('ChangeOrgRegistration'),
    'org-vat-id:change': jwtPermissions.includes('ChangeVatId'),
    'org-foundation-date:change': jwtPermissions.includes('ChangeFoundation'),
    'org-integrations:view': jwtPermissions.includes('GetIntegrationSettings'),
    'org-payment-deferment:change': jwtPermissions.includes(
      'ChangePaymentDeferment'
    ),
    'org-expected-monthly-spend:view': jwtPermissions.includes(
      'GetExpectedMonthlySpend'
    ),
    'org-expected-monthly-spend:change': jwtPermissions.includes(
      'UpdateExpectedMonthlySpend'
    ),
    'missing-receipt-notification:toggle': jwtPermissions.includes(
      'ToggleMissingReceiptNotifications'
    ),
    'omr-feature:toggle': jwtPermissions.includes('ToggleOmrFeature'),
    'sustainability-feature:toggle': jwtPermissions.includes(
      'ToggleSustainabilityFeature'
    ),
    'circula-settings:change': jwtPermissions.includes('ManageCirculaSettings'),

    'bank-connection:remove': (
      bankConnection: BankConnection | null | undefined,
      bankAccountsOfConnection: BankAccount[]
    ) =>
      jwtPermissions.includes('DeleteBankConnection') &&
      !!bankConnection &&
      bankAccountsOfConnection.every((item) => !item.directDebit),
    'bank-connection:confirm': (bankAccounts: BankAccount[]) =>
      jwtPermissions.includes('ConfirmBankAccounts') && !!bankAccounts.length,
    'bank-connection:view-error': hasSome(roles, INTERNAL_ADMIN_ROLES),
    'bank-account:add': () =>
      hasAll(jwtPermissions, [
        'LookupBankInfo',
        'GetBankAccounts',
        'AddBankAccount',
      ]) &&
      !!organization &&
      !!defaultCardAccount &&
      defaultCardAccount.accountType.value !==
        OrganizationAccountType.prefunded &&
      !organization.bankConnectionRequired,
    'bank-account:connect':
      hasAll(jwtPermissions, [
        'SearchBankForConnection',
        'ImportBankConnection',
        'UpdateBankConnection',
      ]) &&
      !!organization &&
      !!defaultCardAccount &&
      defaultCardAccount.accountType.value !==
        OrganizationAccountType.prefunded &&
      organization.bankConnectionRequired,
    'bank-account:delete': (bankAccount: BankAccount) => {
      if (
        !organization ||
        !jwtPermissions.includes('DeleteBankAccount') ||
        bankAccount.detectedAsTransferSender ||
        bankAccount.directDebit
      )
        return false;

      if (bankAccount.bankConnectionId) {
        return byRole(roles, {
          [Role.orgAdmin]:
            organization.status === OrganizationStatus.onboardingRiskData,
          [Role.accountOwner]:
            organization.status === OrganizationStatus.onboardingRiskData,
          [Role.platformAdmin]: true,
          [Role.creditOps]: true,
          [Role.sales]: true,
        });
      } else {
        return byRole(roles, {
          [Role.orgAdmin]: true,
          [Role.accountOwner]: true,
          [Role.platformAdmin]: true,
          [Role.creditOps]: true,
          [Role.sales]: true,
        });
      }
    },
    'bank-account:set-as-billing': (bankAccount: BankAccount) =>
      hasAll(jwtPermissions, ['ConfirmDirectDebit', 'AddBankAccount']) &&
      !!organization &&
      organization.status === OrganizationStatus.active &&
      (!bankAccount.detectedAsTransferSender ||
        (bankAccount.detectedAsTransferSender &&
          bankAccount.transfersAllowedStatus ===
            BankAccountTransfersAllowedStatus.authorized)) &&
      !bankAccount.directDebit,
    'bank-account:upload-sdd-mandate-file': jwtPermissions.includes(
      'UploadSignedSdd'
    ),
    'bank-account:set-as-cashback-payout': (bankAccount: BankAccount) =>
      hasAll(jwtPermissions, ['UpdateBankAccount']) &&
      !!organization &&
      organization.status === OrganizationStatus.active &&
      bankAccount.detectedAsTransferSender &&
      !bankAccount.cashbackPayout,
    'bank-account-blocked:view': hasSome(roles, INTERNAL_ADMIN_ROLES),

    'min-payment-frequency:change': jwtPermissions.includes(
      'SetMinPaymentFrequency'
    ),
    'current-payment-frequency:change': jwtPermissions.includes(
      'SetPaymentFrequency'
    ),

    'program-settlements:update': jwtPermissions.includes('TriggerPayment'),

    'cashback:add': jwtPermissions.includes('OverwriteCustomCashback'),
    'cashback:redeem': (currencyCashback: CurrencyCashback) =>
      jwtPermissions.includes('RedeemOrganizationCashback') &&
      greaterThanOrEqual(
        dineroFromMoney(currencyCashback.totalRedeemable),
        dineroFromMoney(currencyCashback.cashbackDefaults.minRedeemableCashback)
      ),
    'cashback-settings:update': jwtPermissions.includes(
      'UpdateCashbackInformation'
    ),

    'member-first:create':
      checkPermissionWithEntity(jwtPermissions, 'CreateMember', '') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'member-cardholder:create':
      checkPermissionWithEntity(
        jwtPermissions,
        'CreateMember',
        Role.cardholder
      ) && !isOrgInOnboarding,
    'member-accountant:create':
      checkPermissionWithEntity(
        jwtPermissions,
        'CreateMember',
        Role.accountant
      ) && !isOrgInOnboarding,
    'member-org-admin:create': (members?: Member[] | null) =>
      checkPermissionWithEntity(
        jwtPermissions,
        'CreateMember',
        Role.orgAdmin
      ) &&
      byRole(roles, {
        [Role.accountOwner]: true,
        [Role.creditOps]: !!members?.length && isOrgInOnboarding,
        [Role.sales]: !!members?.length && isOrgInOnboarding,
        [Role.platformAdmin]:
          !!members?.length && (isOrgInOnboarding || isOrgActive),
        [Role.superPlatformAdmin]:
          !!members?.length && (isOrgInOnboarding || isOrgActive),
      }),
    'member-account-owner:create':
      checkPermissionWithEntity(
        jwtPermissions,
        'CreateMember',
        Role.accountOwner
      ) &&
      byRole(roles, {
        [Role.accountOwner]: true,
        [Role.creditOps]: true,
        [Role.sales]: isOrgInOnboarding,
        [Role.platformAdmin]: isOrgInOnboarding || isOrgActive,
        [Role.superPlatformAdmin]: isOrgInOnboarding || isOrgActive,
      }),
    'member-auditor:create':
      checkPermissionWithEntity(jwtPermissions, 'CreateMember', Role.auditor) &&
      !isOrgInOnboarding,
    'member:resend-invitation': jwtPermissions.includes(
      'ResendInvitationEmail'
    ),
    'member-details:change': (member: MemberDetails, managerTeam?: Team) =>
      checkPermissionWithEntity(jwtPermissions, 'ChangeMemberDetails', '') &&
      member.status !== MemberStatus.deactivated &&
      byRole(roles, {
        [Role.teamManager]:
          !!managerTeam &&
          getMemberRole(member.roles) === Role.cardholder &&
          member.status === MemberStatus.invited,
        [Role.accountant]: member.id === currentMember.id,
        [Role.orgAdmin]: !member.roles.includes(Role.accountOwner),
        [Role.accountOwner]: true,
        [Role.creditOps]: true,
        [Role.sales]: true,
        [Role.platformAdmin]: true,
        [Role.customerSupport]: true,
      }),
    'member-teams:change': (
      activeTeams: Team[],
      managerTeam?: Team,
      member?: MemberDetails
    ) =>
      jwtPermissions.includes('ManageTeamMemberships') &&
      member?.status !== MemberStatus.deactivated &&
      !!activeTeams.length &&
      (!!managerTeam ? member?.id !== currentMember.id : true),
    'member-legal-rep-role:change':
      hasSome(roles, INTERNAL_ADMIN_ROLES) &&
      checkPermissionWithEntity(jwtPermissions, 'UpdateLegalRep', ''),
    'member-permissions-role:view': (member: MemberDetails) =>
      ((checkPermissionWithEntity(
        jwtPermissions,
        'UpdateMemberRoles',
        getMemberRole(member.roles)
      ) ||
        jwtPermissions.includes('UpdateMemberRoles')) && // CreditOps
        member.status !== MemberStatus.deactivated &&
        member.id !== currentMember.id) ||
      currentMember.roles?.includes(Role.auditor),
    get 'member-permissions-role:update'() {
      return (
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.orgSettingsManager
        ) ||
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.orgModulesManager
        ) ||
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.orgIntegrationManager
        ) ||
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.accountingSettingsManager
        ) ||
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.accountingExportManager
        ) ||
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.orgAdmin
        ) ||
        checkPermissionWithEntity(
          jwtPermissions,
          'UpdateMemberRoles',
          Role.accountant
        ) ||
        jwtPermissions.includes('UpdateMemberRoles') // CreditOps
      );
    },
    'member-org-settings-manager-role:update': checkPermissionWithEntity(
      jwtPermissions,
      'UpdateMemberRoles',
      Role.orgSettingsManager
    ),
    'member-org-modules-manager-role:update': checkPermissionWithEntity(
      jwtPermissions,
      'UpdateMemberRoles',
      Role.orgModulesManager
    ),
    'member-org-integration-manager-role:update': checkPermissionWithEntity(
      jwtPermissions,
      'UpdateMemberRoles',
      Role.orgIntegrationManager
    ),
    'member-accounting-settings-manager-role:update': checkPermissionWithEntity(
      jwtPermissions,
      'UpdateMemberRoles',
      Role.accountingSettingsManager
    ),
    'member-accounting-export-manager-role:update': checkPermissionWithEntity(
      jwtPermissions,
      'UpdateMemberRoles',
      Role.accountingExportManager
    ),
    'member-permissions:change': (member?: MemberDetails) =>
      checkPermissionWithEntity(
        jwtPermissions,
        'ChangeMemberPermissions',
        Role.orgAdmin
      ) && member?.status !== MemberStatus.deactivated,
    'member-email:change': (member: MemberDetails, managerTeam?: Team) =>
      checkPermissionWithEntity(jwtPermissions, 'ChangeEmail', '') &&
      member.status !== MemberStatus.deactivated &&
      member.id !== currentMember.id &&
      !member.multiOrgUser &&
      byRole(roles, {
        [Role.teamManager]:
          !!managerTeam &&
          getMemberRole(member.roles) === Role.cardholder &&
          member.status === MemberStatus.invited,
        [Role.orgAdmin]: !member.roles.includes(Role.accountOwner),
        [Role.accountOwner]: true,
        [Role.platformAdmin]:
          member.status === MemberStatus.invited &&
          hasSome(member.roles, [Role.orgAdmin, Role.accountOwner]),
        [Role.customerSupport]: true,
      }),
    'member-multi-org-email:change': (member: MemberDetails) =>
      checkPermissionWithEntity(
        jwtPermissions,
        'ChangeEmailMultiorgUser',
        ''
      ) &&
      member.status !== MemberStatus.deactivated &&
      member.id !== currentMember.id &&
      member.multiOrgUser,
    'member-security-key:unpair': (member: MemberDetails) =>
      member.status === MemberStatus.active &&
      member.securityKeyPaired &&
      checkPermissionWithEntity(jwtPermissions, 'ChangeMemberMfa', '') &&
      byRole(roles, {
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.platformAdmin]: true,
      }),
    'member-phone-number:change': (member: MemberDetails) =>
      checkPermissionWithEntity(jwtPermissions, 'ChangeMemberPhone', '') &&
      member.status !== MemberStatus.deactivated &&
      member.phoneSynced &&
      byRole(roles, {
        [Role.accountant]: member.id === currentMember.id,
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.sales]: true,
        [Role.platformAdmin]: true,
        [Role.customerSupport]: true,
      }),
    'member-sms-logs:view': () => jwtPermissions.includes('GetSmsLogs'),
    'member:deactivate': (member: MemberDetails, managerTeam?: Team) =>
      checkPermissionWithEntity(jwtPermissions, 'DeactivateMember', '') &&
      member.status !== MemberStatus.deactivated &&
      member.id !== currentMember.id &&
      byRole(roles, {
        [Role.teamManager]:
          !!managerTeam &&
          member.teams.length === 1 &&
          managerTeam.id === member.teams[0].teamId &&
          !hasSome(member.roles, [Role.orgAdmin, Role.accountOwner]),
        [Role.orgAdmin]: !member.roles.includes(Role.accountOwner),
        [Role.accountOwner]: true,
        [Role.sales]: true,
        [Role.platformAdmin]: true,
        [Role.customerSupport]: true,
      }),
    'member:reactivate': (member: MemberDetails) =>
      jwtPermissions.includes('ReactivateMembership') &&
      member.status === MemberStatus.deactivated &&
      organization?.status !== OrganizationStatus.cancelled &&
      organization?.status !== OrganizationStatus.deactivated,
    'member:bulk-upload': byRole(roles, {
      [Role.superPlatformAdmin]: true,
    }),

    'team:get-all':
      jwtPermissions.includes('GetTeams') && hasSome(roles, ADMIN_ROLES),
    'team:manage': roles.includes(Role.teamManager),
    'team:create': hasAll(jwtPermissions, [
      'CreateTeam',
      'ManageTeamMemberships',
    ]),
    'team-details:change': (team: Team) =>
      jwtPermissions.includes('UpdateTeam') &&
      team.teamStatus === TeamStatus.active,
    'team-review-tx:change': hasAll(jwtPermissions, [
      'UpdateTeam',
      'UpdateTeamTxReviewRequired',
    ]),
    'team-cost-center:assign':
      jwtPermissions.includes('UpdateTeam') &&
      (roles.includes(Role.accountingSettingsManager) ||
        roles.includes(Role.accountOwner)), // Remove it only when cost center assignment has a dedicated permission
    'team-cost-center:change': (team: Team) =>
      jwtPermissions.includes('UpdateTeam') &&
      team.teamStatus === TeamStatus.active &&
      (roles.includes(Role.accountingSettingsManager) ||
        roles.includes(Role.accountOwner)),
    'team-members:change': (team: Team) =>
      jwtPermissions.includes('ManageTeamMemberships') &&
      team.teamStatus === TeamStatus.active &&
      !roles.includes(Role.accountant),
    'team-managers:change': (team: Team) =>
      jwtPermissions.includes('ManageTeamMemberships') &&
      team.teamStatus === TeamStatus.active &&
      byRole(roles, {
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.platformAdmin]: true,
      }),
    'team-permissions:change': (team: Team) =>
      jwtPermissions.includes('UpdateTeamPermissions') &&
      team.teamStatus === TeamStatus.active,
    'team-budget:change': () =>
      jwtPermissions.includes('UpdateTeam') &&
      byRole(roles, {
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
      }),
    'team:deactivate': (team: Team) =>
      jwtPermissions.includes('UpdateTeam') &&
      team.teamStatus === TeamStatus.active &&
      !team.managers.length &&
      !team.members.length,
    'team:delete': (team: Team) =>
      jwtPermissions.includes('DeleteTeam') &&
      team.teamStatus === TeamStatus.deactivated,
    'team:bulk-upload': byRole(roles, {
      [Role.superPlatformAdmin]: true,
    }),

    'card:create': jwtPermissions.includes('CreateCard') && !isOrgInOnboarding,
    'card:request': !roles.includes(Role.auditor),
    // should only be used together with the `card:create` permission and when member is known
    'card:create-for-member': (
      member: Member | MemberDetails,
      managerTeam?: Team
    ) =>
      member.status !== MemberStatus.deactivated &&
      !member.roles.includes(Role.auditor) &&
      byRole(roles, {
        [Role.teamManager]:
          !!managerTeam &&
          managerTeam.maxLimitPerCard.value > 0 &&
          (managerTeam.teamManagerCanIssueCardForSelf ||
            member.id !== currentMember.id),
        [Role.orgAdmin]:
          typeof attr.canCreateCardForSelf === 'boolean' &&
          !attr.canCreateCardForSelf
            ? member.id !== currentMember.id
            : true,
        [Role.accountOwner]: true,
      }),
    'card:replace': (card: Card, managerTeam?: Team) =>
      jwtPermissions.includes('RenewCard') &&
      !isCardVoid(card) &&
      card.simpleStatus !== CardSimpleStatus.pending &&
      card.type !== CardType.singleUse &&
      !card.replacedByCardId &&
      byRole(roles, {
        [Role.superPlatformAdmin]: true,
        [Role.accountOwner]: true,
        [Role.orgAdmin]:
          !!attr.maxSpendLimitPerCard &&
          attr.maxSpendLimitPerCard.value >= card.limit.value,
        [Role.teamManager]:
          !!managerTeam &&
          !!managerTeam.maxLimitPerCard &&
          managerTeam.maxLimitPerCard.value >= card.limit.value,
        [Role.paymentOps]: true,
      }),
    'card:terminate': (card: Card, managerTeam?: Team) =>
      checkPermissionWithEntity(jwtPermissions, 'TerminateCard', '') &&
      !isCardVoid(card) &&
      byRole(roles, {
        [Role.cardholder]: card.memberId === currentMember.id,
        [Role.teamManager]: managerTeam?.id === card.teamId,
        [Role.accountant]: card.memberId === currentMember.id,
        [Role.sales]: true,
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.platformAdmin]: true,
        [Role.paymentOps]: true,
        [Role.customerSupport]: true,
      }),
    'card:lock-unlock': (card: Card, managerTeam?: Team) =>
      checkPermissionWithEntity(jwtPermissions, 'LockUnlockCard', '') &&
      !isCardVoid(card) &&
      card.simpleStatus !== CardSimpleStatus.pending &&
      card.status !== CardStatus.lockedPin &&
      byRole(roles, {
        [Role.cardholder]: card.memberId === currentMember.id,
        [Role.teamManager]: managerTeam?.id === card.teamId,
        [Role.accountant]: card.memberId === currentMember.id,
        [Role.sales]: true,
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.platformAdmin]: true,
        [Role.paymentOps]: true,
        [Role.customerSupport]: true,
      }),
    'card:connect-to-circula': (card: Card) =>
      jwtPermissions.includes('CirculaConnectCard') &&
      !isCardVoid(card) &&
      !!organization?.integrations.includes(IntegrationType.circula),
    'new-card:connect-to-circula':
      jwtPermissions.includes('CirculaConnectCard') &&
      hasSome(roles, [Role.orgAdmin, Role.accountOwner]),
    'card-name:change': (card: Card, managerTeam?: Team) =>
      checkPermissionWithEntity(jwtPermissions, 'UpdateCardDetails', '') &&
      !isCardVoid(card) &&
      card.type === CardType.virtual &&
      byRole(roles, {
        [Role.cardholder]: card.memberId === currentMember.id,
        [Role.teamManager]: managerTeam?.id === card.teamId,
        [Role.accountant]: card.memberId === currentMember.id,
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.paymentOps]: true,
      }),
    'card-controls:change': (card: Card, managerTeam?: Team) =>
      jwtPermissions.includes('UpdateCardControls') &&
      !isCardVoid(card) &&
      byRole(roles, {
        [Role.teamManager]: managerTeam?.id === card.teamId,
        [Role.orgAdmin]: true,
        [Role.accountOwner]: true,
        [Role.paymentOps]: true,
      }),
    'card-team:assign': byRole(roles, {
      [Role.orgAdmin]: true,
      [Role.accountOwner]: true,
    }),
    'card-team:change': (card: Card) =>
      !isCardVoid(card) && jwtPermissions.includes('UpdateCardTeam'),
    'card-project:change': (
      card: Card,
      accountingSettings: AccountingSettings,
      projects: Project[]
    ) =>
      accountingSettings.projectEnabled &&
      !!projects.length &&
      jwtPermissions.includes('UpdateCardProject'),
    'card-limits:change': (card: Card, managerTeam?: Team) =>
      hasSome(jwtPermissions, [
        'ChangeCardLimits',
        'EditPlatformFeeCardDetails',
      ]) &&
      !isCardVoid(card) &&
      card.status !== CardStatus.lockedPin &&
      card.simpleStatus !== CardSimpleStatus.requested &&
      byRole(roles, {
        [Role.teamManager]:
          !!managerTeam &&
          managerTeam.maxLimitPerCard.value > 0 &&
          (managerTeam.teamManagerCanIssueCardForSelf ||
            card.memberId !== currentMember.id),
        [Role.orgAdmin]:
          typeof attr.canCreateCardForSelf === 'boolean' &&
          !attr.canCreateCardForSelf
            ? card.memberId !== currentMember.id
            : true,
        [Role.accountOwner]: true,
        [Role.superPlatformAdmin]: true,
      }),
    'platform-fee-card:view':
      jwtPermissions.includes('GetPlatformFeeCardDetails') &&
      !!organization &&
      organization.status === OrganizationStatus.active,
    'platform-fee-card:manage': jwtPermissions.includes(
      'EditPlatformFeeCardDetails'
    ),
    'card-purpose:change': (card: Card) =>
      checkPermissionWithEntity(jwtPermissions, 'UpdateCardDetails', '') &&
      !isCardVoid(card),
    'card-validity-period:change': (
      card: Card,
      cardConfigSetting: CardConfigSetting | undefined
    ) =>
      ([CardSimpleStatus.active, CardSimpleStatus.locked].includes(
        card.simpleStatus
      ) ||
        card.status === CardStatus.pendingValidity) &&
      !!cardConfigSetting &&
      cardConfigSetting.validityPeriodEnabled &&
      checkPermissionWithEntity(jwtPermissions, 'UpdateCardDetails', '') &&
      hasSome(roles, [Role.teamManager, Role.orgAdmin, Role.accountOwner]),
    'card-limit:create-change-request': (card: Card) =>
      !isCardVoid(card) &&
      !card.limitChangeRequestId &&
      jwtPermissions.includes('CreateCardLimitChangeRequest'),
    'card-limit:approve-change-request': (
      card: Card,
      limit: number,
      managerTeam?: Team
    ) => {
      const ownCard = card.memberId === currentMember.id;
      const canCreateCardForSelf =
        typeof attr.canCreateCardForSelf === 'boolean' &&
        !attr.canCreateCardForSelf;
      const teamManagerMaxLimitPerCardAllowed =
        !!managerTeam && managerTeam.maxLimitPerCard.value >= limit;
      const orgAdminMaxLimitPerCardAllowed =
        !attr.maxSpendLimitPerCard || attr.maxSpendLimitPerCard.value >= limit;

      return (
        jwtPermissions.includes('ApproveCardLimitChangeRequest') &&
        byRole(roles, {
          [Role.teamManager]:
            teamManagerMaxLimitPerCardAllowed &&
            (managerTeam?.teamManagerCanIssueCardForSelf || !ownCard),
          [Role.orgAdmin]: canCreateCardForSelf
            ? orgAdminMaxLimitPerCardAllowed && !ownCard
            : orgAdminMaxLimitPerCardAllowed,
          [Role.accountOwner]: true,
        })
      );
    },
    'card:view-in-another-app': (card: Card) =>
      card.memberId === currentMember.id &&
      !isCardVoid(card) &&
      hasSome(roles, CARDHOLDER_ROLES) &&
      hasSome(roles, EXTERNAL_ADMIN_ROLES),
    'card-request:approve': (card: Card, limit: number, managerTeam?: Team) => {
      const ownCard = card.memberId === currentMember.id;
      const canCreateCardForSelf =
        typeof attr.canCreateCardForSelf === 'boolean' &&
        !attr.canCreateCardForSelf;
      const teamManagerMaxLimitPerCardAllowed =
        !!managerTeam && managerTeam.maxLimitPerCard.value >= limit;
      const orgAdminMaxLimitPerCardAllowed =
        !attr.maxSpendLimitPerCard || attr.maxSpendLimitPerCard.value >= limit;

      return (
        jwtPermissions.includes('ApproveCardRequest') &&
        byRole(roles, {
          [Role.teamManager]:
            teamManagerMaxLimitPerCardAllowed &&
            (managerTeam?.teamManagerCanIssueCardForSelf || !ownCard),
          [Role.orgAdmin]: canCreateCardForSelf
            ? orgAdminMaxLimitPerCardAllowed && !ownCard
            : orgAdminMaxLimitPerCardAllowed,
          [Role.accountOwner]: true,
        })
      );
    },
    'card-velocity:change': (card: Card) =>
      card.type !== CardType.singleUse &&
      jwtPermissions.includes('UpdateCardVelocityGroup'),
    'card:bulk-upload': byRole(roles, {
      [Role.superPlatformAdmin]: true,
    }),

    'transaction:export': jwtPermissions.includes('ExportTransactions'),
    'transaction-dispute:view': hasSome(roles, INTERNAL_ADMIN_ROLES),
    'transaction-dispute:change':
      hasSome(roles, INTERNAL_ADMIN_ROLES) &&
      jwtPermissions.includes('UpdateTransactionDisputeStatus'),
    'transaction-comment:update': jwtPermissions.includes(
      'UpdateTransactionDetails'
    ),
    'transaction-review:view':
      hasSome(roles, INTERNAL_ADMIN_ROLES) ||
      (jwtPermissions.includes('UpdateTransactionDetails') &&
        hasSome(roles, [Role.orgAdmin, Role.accountOwner, Role.accountant])),
    'transaction-review:change':
      jwtPermissions.includes('UpdateTransactionDetails') &&
      hasSome(roles, [Role.orgAdmin, Role.accountOwner, Role.accountant]),
    'team-transaction-review:view':
      jwtPermissions.includes('UpdateTransactionDetails') &&
      roles.includes(Role.teamManager),
    'team-transaction-review:change': () =>
      jwtPermissions.includes('UpdateTransactionDetails') &&
      roles.includes(Role.teamManager),
    'transaction-team:change': jwtPermissions.includes(
      'UpdateTransactionDetails'
    ),
    'transactions-csv:download': jwtPermissions.includes('GetTransactions'),
    'transactions-zip:download': hasAll(jwtPermissions, [
      'GetTransactions',
      'DownloadTransactionReceipts',
    ]),
    'transactions-datev-api:export': jwtPermissions.includes('DatevExport'),

    'subcategory:create': jwtPermissions.includes('CreateSubcategories'),
    'subcategory:update': jwtPermissions.includes('UpdateSubcategories'),
    'subcategory:assign': jwtPermissions.includes('AssignSubcategory'),
    'subcategory:delete': jwtPermissions.includes('DeleteSubcategories'),

    'vat-rate:create': jwtPermissions.includes('CreateVatRates'),
    'vat-rate:update': jwtPermissions.includes('UpdateVatRates'),
    'vat-rate:assign': jwtPermissions.includes('AssignVatRate'),
    'vat-rate:delete': jwtPermissions.includes('DeleteVatRates'),

    'card-account-settings:update': jwtPermissions.includes(
      'UpdateAccountingSettings'
    ),

    'project:create':
      jwtPermissions.includes('CreateProject') &&
      (roles.includes(Role.accountingSettingsManager) ||
        roles.includes(Role.accountOwner)),
    'project:update':
      jwtPermissions.includes('UpdateProject') &&
      (roles.includes(Role.accountingSettingsManager) ||
        roles.includes(Role.accountOwner)),
    'project:delete':
      jwtPermissions.includes('DeleteProject') &&
      (roles.includes(Role.accountingSettingsManager) ||
        roles.includes(Role.accountOwner)),

    'supplier:view': jwtPermissions.includes('GetSuppliers'),
    'supplier:create': jwtPermissions.includes('CreateSupplier'),
    'supplier:update': jwtPermissions.includes('UpdateSupplier'),
    'supplier:assign': jwtPermissions.includes('AssignSupplier'),
    'supplier:delete': jwtPermissions.includes('DeleteSupplier'),

    'accounting-system:view':
      jwtPermissions.includes('GetAccountingSettings') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'accounting-system:change': hasAll(jwtPermissions, [
      'SetAccountingSystem',
      'GetAvailableAccountingSystems',
    ]),
    'accounting-settings:update': jwtPermissions.includes(
      'UpdateAccountingSettings'
    ),
    'accounting-settings-tiles:update': hasSome(roles, [
      ...INTERNAL_ADMIN_ROLES,
      Role.accountOwner,
      Role.accountingSettingsManager,
    ]),
    'use-supplier-account:change': hasSome(roles, [
      Role.platformAdmin,
      Role.customerSupport,
    ]),
    'datev-export-formats:change':
      jwtPermissions.includes('UpdateAccountingSettings') &&
      hasSome(roles, [Role.customerSupport, Role.sales, Role.platformAdmin]),
    'datev-sub-page:visit': (accountingSettings: AccountingSettings) =>
      accountingSettings?.accountingSystem === AccountingSystem.datev &&
      accountingSettings?.useAccountingExport &&
      hasSome(roles, ADMIN_ROLES),
    'bmd-sub-page:visit': (accountingSettings: AccountingSettings) =>
      accountingSettings?.accountingSystem === AccountingSystem.bmd &&
      accountingSettings?.useAccountingExport &&
      hasSome(roles, ADMIN_ROLES),
    'datev-api-connection:change':
      jwtPermissions.includes('DatevConnectClient') &&
      jwtPermissions.includes('DatevGetClients'),
    'locoia-sub-page:visit': (accountingSettings: AccountingSettings) =>
      ((accountingSettings?.accountingSystem === AccountingSystem.lexOffice &&
        accountingSettings?.useAccountingExport) ||
        isCustomLocoiaAccountingSystem(accountingSettings)) &&
      hasSome(roles, ADMIN_ROLES),
    'accounting-system-subpage:visit': (
      accountingSettings: AccountingSettings
    ) =>
      accountingSettings?.accountingSystemIntegrationMethod ===
        AccountingSystemIntegrationMethod.codat &&
      accountingSettings?.useAccountingExport &&
      hasSome(roles, ADMIN_ROLES),
    'tx-integration-sync:change': jwtPermissions.includes(
      'ManageTransactionSync'
    ),
    'codat-settings:change': jwtPermissions.includes('CodatConnect'),
    'codat-sync:view':
      jwtPermissions.includes('ReadCodatData') ||
      jwtPermissions.includes('ManageCodatData'),
    'codat-sync:change':
      jwtPermissions.includes('ManageCodatData') &&
      jwtPermissions.includes('CreateSubcategories') &&
      jwtPermissions.includes('CreateVatRates') &&
      jwtPermissions.includes('CreateTeam') &&
      jwtPermissions.includes('CreateProject') &&
      jwtPermissions.includes('CreateSupplier'),
    'codat-general-accounts:view':
      jwtPermissions.includes('ReadCodatData') ||
      jwtPermissions.includes('ManageCodatData'),
    'codat-general-accounts:change': jwtPermissions.includes('ManageCodatData'),
    'global-transactions:visit':
      jwtPermissions.includes('GetTransactions') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'merchant:change':
      jwtPermissions.includes('UpdateMerchants') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'automatic-receipt-forwarding:view': jwtPermissions.includes(
      'GetForwardingConfiguration'
    ),
    'automatic-receipt-forwarding:change': jwtPermissions.includes(
      'ManageForwardingConfiguration'
    ),
    'receipts-settings:view': jwtPermissions.includes('ManageReceipts'),
    'receipts-settings:change': jwtPermissions.includes(
      'ManageReceiptSettings'
    ),
    'accounting-settings:view':
      jwtPermissions.includes('GetAccountingSettings') &&
      hasSome(roles, ADMIN_ROLES),

    'subscription-plan:update': jwtPermissions.includes(
      'UpdateSubscriptionPlans'
    ),
    'subscription-plan:upgrade':
      subscriptionPlan &&
      [SubscriptionPlanType.starter, SubscriptionPlanType.light].includes(
        subscriptionPlan.type
      ) &&
      jwtPermissions.includes('UpdateSubscriptionPlans') &&
      byRole(roles, {
        [Role.accountOwner]: true,
        [Role.orgAdmin]: true,
      }),
    'subscription-plan-settings:view':
      organization?.status === OrganizationStatus.active &&
      jwtPermissions.includes('GetSubscriptionPlans') &&
      byRole(roles, {
        [Role.orgAdmin]: !!subscriptionPlan?.showPlanPage,
        [Role.accountOwner]: !!subscriptionPlan?.showPlanPage,
        [Role.creditOps]: true,
        [Role.sales]: true,
        [Role.platformAdmin]: true,
        [Role.customerSupport]: true,
      }),

    'partners:view': jwtPermissions.includes('GetPartnerAuthorization'),
    'partners:change':
      jwtPermissions.includes('ManagePartnerAuthorization') &&
      hasSome(roles, [Role.accountOwner, Role.orgAdmin]),
    'partner-config:view': jwtPermissions.includes('GetPartnerConfig'),
    'organization-partner-connection:change': jwtPermissions.includes(
      'ManagePartnerAuthorization'
    ),
    'organization-partner:change':
      jwtPermissions.includes('ChangePartner') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'share-onboarding-status:change': jwtPermissions.includes(
      'ManagePartnerAuthorization'
    ),
    'org-hubspot-id:view': hasSome(roles, INTERNAL_ADMIN_ROLES),
    'org-hubspot-id:update': jwtPermissions.includes('UpdateHubspotData'),
    'payout-payment:create': jwtPermissions.includes('CreatePayoutPayment'),
    'payout-payment-for-ccreu-card-account:create':
      jwtPermissions.includes('CreatePayoutPayment') &&
      hasSome(roles, INTERNAL_ADMIN_ROLES),
    'payout-payment-for-deactivated-card-account:create': hasSome(
      roles,
      INTERNAL_ADMIN_ROLES
    ),
    'locoia-settings:view': jwtPermissions.includes('GetLocoiaSettings'),
    'locoia-settings:change': jwtPermissions.includes('ManageLocoiaSettings'),

    'black-cards-list:view':
      jwtPermissions.includes('GetCards') && hasSome(roles, ADMIN_ROLES),

    'terms-and-conditions:accept': (openActiveOptIn: OpenActiveOptIn) =>
      jwtPermissions.includes('AcceptTermsAndConditions') &&
      !openActiveOptIn?.acceptedBy?.includes(currentMember.id),
    'terms-and-conditions-updated-dialog:view': (
      openActiveOptIn: OpenActiveOptIn,
      expiredDate: boolean
    ) => {
      if (openActiveOptIn) {
        const acceptedByMe = openActiveOptIn.acceptedBy === currentMember.id;
        const notEnoughLegalRepsAccepted =
          !openActiveOptIn.acceptedBy ||
          (currentMember.legalRepresentative ===
            LegalRepresentativeType.joint &&
            !acceptedByMe);

        return byRole(roles, {
          [Role.legalRep]: notEnoughLegalRepsAccepted || expiredDate,
          [Role.cardholder]: expiredDate,
        });
      }
      return false;
    },
    'terms-or-policies:update': jwtPermissions.includes(
      'UploadComplianceDocument'
    ),
    'white-label-members-page:visit': (
      isComplianceRiskWhiteLabelApp: boolean
    ) =>
      isComplianceRiskWhiteLabelApp &&
      jwtPermissions.includes('GetMembers') &&
      hasSome(roles, ADMIN_ROLES),
    'delayed-payment:retry': jwtPermissions.includes('ManageBills'),
    'delayed-payment:discard': jwtPermissions.includes('ManageBills'),
    'delayed-payment:convert-to-wt': jwtPermissions.includes('ManageBills'),
    'consulting-session:book':
      isPliantOrPliantDefault &&
      (organization?.serviceLevel === ServiceLevel.A ||
        (organization?.serviceLevel === ServiceLevel.B &&
          subscriptionPlan &&
          [
            SubscriptionPlanType.standard,
            SubscriptionPlanType.premium,
            SubscriptionPlanType.custom,
          ].includes(subscriptionPlan.type))),
    'accounting-webinar:book':
      organization?.billingAddress.country &&
      [SupportedCountry.at, SupportedCountry.ch, SupportedCountry.de].includes(
        organization.billingAddress.country
      ),
    'representative:save': jwtPermissions.includes('SaveRepresentative'),
    'varengold-submission:create-or-submit':
      jwtPermissions.includes('CreateVarengoldSubmission') &&
      jwtPermissions.includes('SubmitVarengoldSubmission'),
    'custom-fields:view': jwtPermissions.includes('GetTransactionCustomFields'),
    'custom-fields:manage': jwtPermissions.includes(
      'ManageTransactionCustomFields'
    ),
    'private-transaction:change': !roles.includes(Role.auditor),
    'private-transaction-restricted:change': hasSome(roles, [
      Role.accountOwner,
      Role.orgAdmin,
      Role.accountant,
    ]),
    'receipt-inbox:view': jwtPermissions.includes('UseReceiptInbox'),
    'receipt-inbox:change': jwtPermissions.includes('UseReceiptInbox'),
    'receipt-inbox-matched-tx:view': jwtPermissions.includes('GetTransactions'),
  };
};

export type Permissions = ReturnType<typeof getPermissions>;

export type PermissionKey = keyof Permissions;

export default getPermissions;
